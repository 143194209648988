/**Nhap danh muc chu tau */
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormLabel,
  Grid,
  OutlinedInput,
} from "@mui/material";
import React from "react";
import Dropzone from "react-dropzone";
import ReactDataGrid from "@inovua/reactdatagrid-enterprise";
import "@inovua/reactdatagrid-enterprise/index.css";
import "react-toastify/dist/ReactToastify.css";
import NumericFormat from "react-number-format";
/*----------------------Cac control trong project su dung den trong form----------------*/
import uuid from "react-uuid";
import {
  ReactDataGrid_i18n,
  formStyles,
  handleServerError,
  loadDataError,
  showError,
} from "../components/common";
import MyDateEditor from "../components/MyDateEditor";
/*-----------------------Cac thu vien dung chung -------------------------*/
const myLib = require("../lib/MyLib");
const server = require("../lib/server");
const dataStruct = require("../data/DataStruct");
/*-----------------------Cac dinh nghia ----------------------------------*/
const englishUI = true;

/*-----------------------Dinh nghia cac label tieng Anh, tieng Viet su dung trong form*/
const formTitle = englishUI ? "QUOTE EVALUATE" : "CHẤM ĐIỂM CHÀO GIÁ";
const fieldLabels = englishUI
  ? {
      MaHangMuc: "Repair Item:",
      TenCongTy: "Company:",
      PheDuyet: "Confirmed:",
      SoQCF: "QCF No:",
      BaseNo: "Base No:",
      NgayPheDuyet: "Confirm date:",
      Attachs: "File:",
      ResultLog: "File problem:",
    }
  : {
      MaHangMuc: "Report file:",
      TenCongTy: "Company:",
      PheDuyet: "Phê duyệt:",
      SoQCF: "Số QCF:",
      BaseNo: "Số Base:",
      NgayPheDuyet: "Ngày phê duyệt:",
      Attachs: "File:",
      ResultLog: "File problem:",
    };
const buttonLabels = englishUI
  ? {
      btnSave: "Evaluate",
      btnClose: "Close",
      btnExport: "Export QCF",
    }
  : {
      btnSave: "Chấm điểm",
      btnClose: "Thoát",
      btnExport: "Xuất file QCF",
    };
const defaultSortInfo = { name: "MaCongTy", dir: 0, type: "string" };
const columns_grid = [
  {
    name: "MaCongTy",
    type: "string",
    headerAlign: "center",
    defaultWidth: 90,
    header: englishUI ? "ID" : "ID",
  },
  {
    name: "TenCongTy",
    type: "string",
    headerAlign: "center",
    defaultWidth: 250,
    header: englishUI ? "Name" : "Tên nhà thầu",
  },
  {
    name: "TongDiem",
    type: "number",
    headerAlign: "center",
    defaultWidth: 90,
    header: englishUI ? "Summary" : "Điểm",
  },
  {
    name: "DuocChon",
    type: "number",
    headerAlign: "center",
    defaultWidth: 90,
    header: englishUI ? "Selected" : "Điểm",
    render: ({ data }) => (
      <input type="checkbox" readOnly checked={data.DuocChon}></input>
    ),
  },
  {
    name: "TongTien",
    type: "number",
    headerAlign: "center",
    defaultWidth: 120,
    format: "HH:mm DD/MM/YYYY",
    header: englishUI ? "1.Price" : "Chào giá",
    render: ({ data }) => (
      <NumericFormat
        value={data.TongTien ?? ""}
        displayType="text"
        thousandSeparator=","
      />
    ),
  },
  {
    name: "DiemTongTien",
    type: "number",
    headerAlign: "center",
    defaultWidth: 90,
    header: englishUI ? "1.Point" : "Điểm",
  },
  {
    name: "HanThanhToan",
    type: "number",
    headerAlign: "center",
    defaultWidth: 90,
    header: englishUI ? "2.Payment Time" : "Hạn thanh toán",
  },
  {
    name: "DiemThanhToan",
    type: "number",
    headerAlign: "center",
    defaultWidth: 90,
    header: englishUI ? "2.Point" : "Điểm",
  },
  {
    name: "TotalPoint",
    type: "number",
    headerAlign: "center",
    defaultWidth: 120,
    header: englishUI ? "3.Contractor Point" : "Điểm",
  },
  {
    name: "DiemNam",
    type: "number",
    headerAlign: "center",
    defaultWidth: 90,
    header: englishUI ? "3.Point" : "Điểm",
  },
  {
    name: "CoHopDong",
    type: "string",
    headerAlign: "center",
    defaultWidth: 90,
    header: englishUI ? "4.Contract" : "Có HĐ",
  },
  {
    name: "DiemHopDong",
    type: "number",
    headerAlign: "center",
    defaultWidth: 90,
    header: englishUI ? "4.Point" : "Điểm",
  },
  {
    name: "CoBangGia",
    type: "string",
    headerAlign: "center",
    defaultWidth: 90,
    header: englishUI ? "5.Price List" : "Có bảng giá",
  },
  {
    name: "TotalPoint",
    type: "number",
    headerAlign: "center",
    defaultWidth: 90,
    header: englishUI ? "5.Point" : "Điểm",
  },
];
////////////////////////--------------/////////////////////////////////////////
export default class QuoteEvaluateDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      after: {
        FileExcel: {},
      },
      DanhSachNhaThau: [],
      sortInfo: defaultSortInfo, //thong tin sap xep cua grid
      CheckFileLog: [], //ket qua kiem tra file
    };
  }

  //call khi user thay doi trang
  static getDerivedStateFromProps(nextProps, prevState) {
    return { open: nextProps.open };
  }
  saveField = (fieldName, value, callback) => {
    var old_changed = this.state.dataChanged;
    var s = { after: { ...this.state.after } }; //new value
    s.after[fieldName] = value;
    s.dataChanged = true;
    this.setState(s, () => {
      if (callback) {
        callback();
      }
    });
  };
  doSave1 = () => {
    try {
      if (this.state.after.FileExcel.name) {
        this.doImport(this.state.after.FileExcel);
      }
    } catch (ex) {
      showError(ex);
    }
  };
  //thuc hien import file
  doImport(file) {
    try {
      var fr = new FileReader();
      fr.onload = async (e) => {
        const text = e.target.result;
        const raw = Buffer.from(text).toString("base64");
        this.doReadAttachs(raw);
      };
      fr.onerror = (err) => showError(err);
      fr.readAsArrayBuffer(file);
    } catch (ex) {
      showError(ex);
    }
  }
  doReadAttachs(fileContent) {
    //thuc hien doc tiep cac file attachs neu co
    var prom = new Promise((resolve, reject) => {
      var fr = new FileReader();
      fr.onload = async (e) => {
        const text = e.target.result;
        var fileData = {};
        fileData.FileID = uuid();
        fileData.FileName = this.state.after.FileExcel.name;
        fileData.FileContent = fileContent;
        resolve(fileData);
      };
      fr.onerror = reject;
      fr.readAsArrayBuffer(this.state.after.FileExcel);
    });
    //thuc hien viec xu ly
    prom
      .then((fileData) => {
        //thuc hien upload
        this.doUpload(fileData);
      })
      .catch((e) => showError(e));
  }
  doSave = () => {
    //thuc hien download file so sanh chao gia va cho vao de cham diem
    try {
      this.setState({ saving: true });
      server
      .post("ChiPhi/DoRequest", {
        Function: "Proc_CP_HangMucSC_Att_Get",
        ThamSo: {
          HangMucID: this.props.HangMucSC.HangMucID,
          PhanLoai: 1, //file so sanh chao gia
        },
      })
      .then((response) => {
        //hien thi ket qua
        if(response.CP_HangMucSC_Att.length > 0) {
          let file = response.CP_HangMucSC_Att[0];
          this.doUpload({
            FileName: file.FileName,
            FileContent: file.Content,
          });
        }
        else
          throw 'Chưa có file so sánh chào giá (QC)';
      })
      .catch((error) => {
        this.setState({ saving: false });
        myLib.handleServerError(error);
      });
    } catch (ex) {
      showError(ex);
      this.setState({ saving: false });
    }
  };
  //upload du lieu report
  doUpload(attach) {
    this.setState({ saving: true });

    server
      .post("ChiPhi/DoRequest", {
        Function: "Proc_CP_HangMucSC_SoSanhChaoGia",
        ThamSo: {
          HangMucID: this.props.HangMucSC.HangMucID,
          FileName: attach.FileName,
          NoiDungFile: attach.FileContent,
        },
      })
      .then((response) => {
        //hien thi ket qua
        this.setState({
          DanhSachNhaThau: response.ChamDiem,
          saving: false,
        });
      })
      .catch((error) => {
        this.setState({ saving: false });
        myLib.handleServerError(error);
      });
  }

  render() {
    return (
      <Dialog
        open={this.props.open}
        maxWidth={"xl"}
        fullWidth={true}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{formTitle}</DialogTitle>
        <DialogContent>
          <div
            style={{
              minWidth: 500,
              minHeight: 30,
              padding: 5,
              fontSize: 10,
              wordWrap: "break-word",
              overflow: "hidden",
            }}
          >
            <Grid container spacing={1} columns={24}>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.MaHangMuc}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <OutlinedInput
                  id="MaHangMuc"
                  fullWidth
                  readOnly
                  value={this.props.HangMucSC.MaHangMuc}
                />
              </Grid>
            </Grid>
            
              
            <Grid container spacing={1} columns={24}>
              <Grid item xs={24}>
                <ReactDataGrid
                  style={{
                    height: 300,
                    fontSize: 12,
                  }}
                  showZebraRows={true}
                  i18n={englishUI ? undefined : ReactDataGrid_i18n}
                  columns={columns_grid}
                  pagination={true}
                  multiSelect={false}
                  showHoverRows={false}
                  checkboxColumn={{
                    render: (props) => (
                      <input
                        type="checkbox"
                        readOnly
                        checked={props.rowSelected}
                      ></input>
                    ),
                  }}
                  defaultLimit={25}
                  dataSource={this.state.DanhSachNhaThau}
                  idProperty="MaCongTy"
                  headerHeight={30}
                  minRowHeight={30}
                  allowUnsort={false}
                  defaultSortInfo={defaultSortInfo}
                  sortInfo={this.state.sortInfo}
                  emptyText={""}
                  skipLoadOnMount={true}
                  enableSelection={true}
                  onSelectionChange={({ data }) => {}}
                ></ReactDataGrid>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          {this.state.saving ? (
            <CircularProgress size={24} />
          ) : (
            <Button
              onClick={() => {
                this.doSave();
              }}
              variant="contained"
            >
              {buttonLabels.btnSave}
            </Button>
          )}
          <Button
            onClick={() => {
              //xuat file QCF theo ket qua duoc chon
              var thamso = {
                HangMucID: this.props.HangMucSC.HangMucID,
                DanhSachNhaThau: this.state.DanhSachNhaThau,
              };
              window.open(
                server.getRequest(
                  "/ChiPhi/ExportQCF?thamso=" +
                    encodeURIComponent(JSON.stringify(thamso))
                ),
                "_blank"
              );
            }}
            disabled={this.state.DanhSachNhaThau.length == 0}
            variant="contained"
          >
            {buttonLabels.btnExport}
          </Button>
          <Button
            onClick={() => {
              if (this.props.close) {
                this.props.close(false);
              }
            }}
            variant="contained"
          >
            {buttonLabels.btnClose}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
