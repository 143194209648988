/**Nhap danh muc chu tau */
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormLabel,
  Grid,
  OutlinedInput,
} from "@mui/material";
import React from "react";
import Dropzone from "react-dropzone";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
/*----------------------Cac control trong project su dung den trong form----------------*/
import uuid from "react-uuid";
import { showError, showSuccess } from "../components/common";
import MyDateEditor from "../components/MyDateEditor";
import MySelectFilter from "../components/MySelectFilter";
/*-----------------------Cac thu vien dung chung -------------------------*/
const myLib = require("../lib/MyLib");
const server = require("../lib/server");
const dataStruct = require("../data/DataStruct");
/*-----------------------Cac dinh nghia ----------------------------------*/
const englishUI = true;

/*-----------------------Dinh nghia cac label tieng Anh, tieng Viet su dung trong form*/
const formTitle = englishUI
  ? "QUOTE CONFIRM UPLOAD"
  : "UPLOAD BIÊN BẢN CHÀO GIÁ";
const fieldLabels = englishUI
  ? {
      MaHangMuc: "Repair Item:",
      TenCongTy: "Company:",
      PheDuyet: "Confirmed:",
      SoQCF: "QCF No:",
      BaseNo: "Base No:",
      NgayPheDuyet: "Confirm date:",
      Attachs: "File:",
      ResultLog: "File problem:",
    }
  : {
      MaHangMuc: "Report file:",
      TenCongTy: "Company:",
      PheDuyet: "Phê duyệt:",
      SoQCF: "Số QCF:",
      BaseNo: "Số Base:",
      NgayPheDuyet: "Ngày phê duyệt:",
      Attachs: "File:",
      ResultLog: "File problem:",
    };
const buttonLabels = englishUI
  ? {
      btnSave: "Save",
      btnClose: "Close",
    }
  : {
      btnSave: "Lưu",
      btnClose: "Thoát",
    };

////////////////////////--------------/////////////////////////////////////////
export default class QuoteConfirmUploadDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      DanhMuc: {
        DM_CongTy: [],
      },
      after: {
        ...(props.YeuCauCG ? props.YeuCauCG : {}),
        FileBienBan: {
          name: props.YeuCauCG.FileBienBan_FileName,
        },
        PheDuyet: props.YeuCauCG.SoQCF != null,
      },
      CheckFileLog: [], //ket qua kiem tra file
    };
  }

  //call khi user thay doi trang
  static getDerivedStateFromProps(nextProps, prevState) {
    return { open: nextProps.open };
  }
  componentDidMount() {
    //load danh sach cong ty chao gia'
    server
      .post("ChiPhi/DoRequest", {
        Function: "Proc_CP_HangMucSC_GetNhaThau",
        ThamSo: {
          HangMucID: this.props.YeuCauCG.HangMucID,
        },
      })
      .then((response) => {
        this.setState({
          DanhMuc: {
            DM_CongTy: response.DM_CongTy,
          },
        });
      })
      .catch((error) => {
        myLib.handleServerError(error);
      });
  }
  saveField = (fieldName, value, callback) => {
    var old_changed = this.state.dataChanged;
    var s = { after: { ...this.state.after } }; //new value
    s.after[fieldName] = value;
    s.dataChanged = true;
    this.setState(s, () => {
      if (callback) {
        callback();
      }
    });
  };
  saveData = (obj, callback) => {
    var s = {};
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    s.after = { ...this.state.after, ...obj };
    this.setState(s, () => {
      if (callback) {
        callback();
      }
    });
  };

  doSave = () => {
    try {
      if (
        this.state.after.FileBienBan &&
        this.state.after.FileBienBan.size &&
        this.state.after.MaCongTy
      ) {
        //co file att moi thi read file va update
        this.doImport(this.state.after.FileBienBan);
      } else {
        if (this.props.YeuCauCG.FileBienBan && this.state.after.MaCongTy) {
          //da co file roi thi chi can update
          this.doUpload({});
        } else {
          throw "Đề nghị kiểm tra lại dữ liệu!";
        }
      }
    } catch (ex) {
      showError(ex);
    }
  };
  //thuc hien import file
  doImport(file) {
    try {
      var fr = new FileReader();
      fr.onload = async (e) => {
        const text = e.target.result;
        const raw = Buffer.from(text).toString("base64");
        this.doReadAttachs(raw);
      };
      fr.onerror = (err) => showError(err);
      fr.readAsArrayBuffer(file);
    } catch (ex) {
      showError(ex);
    }
  }
  doReadAttachs(fileContent) {
    //thuc hien doc tiep cac file attachs neu co
    var prom = new Promise((resolve, reject) => {
      var fr = new FileReader();
      fr.onload = async (e) => {
        const text = e.target.result;
        var fileData = {};
        fileData.FileID = uuid();
        fileData.FileName = this.state.after.FileBienBan.name;
        fileData.FileContent = fileContent;
        resolve(fileData);
      };
      fr.onerror = reject;
      fr.readAsArrayBuffer(this.state.after.FileBienBan);
    });
    //thuc hien viec xu ly
    prom
      .then((fileData) => {
        //thuc hien upload
        this.doUpload(fileData);
      })
      .catch((e) => showError(e));
  }
  //upload du lieu report
  doUpload(attach) {
    this.setState({ saving: true });

    server
      .post("ChiPhi/DoRequest", {
        Function: "Proc_CP_HangMucSC_Att_Update",
        ThamSo: {
          FileID: this.props.YeuCauCG.FileBienBan,
          HangMucID: this.props.YeuCauCG.HangMucID,
          LoaiFile: "Biên bản chọn nhà thầu",
          PhanLoai: 2,
          FileName: attach.FileName,
          Content: attach.FileContent,
          SoQCF: this.state.after.SoQCF,
          BaseNo: this.state.after.BaseNo,
          NgayPheDuyet: this.state.after.NgayPheDuyet,
          MaCongTy: this.state.after.MaCongTy,
        },
      })
      .then((response) => {
        this.setState({ saving: false });
        showSuccess("Cập nhật thành công!");
        this.props.close(true);
      })
      .catch((error) => {
        this.setState({ saving: false });
        myLib.handleServerError(error);
      });
  }

  render() {
    console.log(this.props.YeuCauCG, this.state.after);
    var attachs = this.state.after.FileBienBan
      ? this.state.after.FileBienBan.name
      : "";
    var logs = "";
    this.state.CheckFileLog.map(
      (item, index) =>
        (logs =
          logs +
          item.STT +
          " - Hàng " +
          item.HangExcel +
          ", cột " +
          item.ColExcel +
          ": " +
          item.MoTa +
          "\n")
    );
    return (
      <Dialog
        open={this.props.open}
        maxWidth={"md"}
        fullWidth={true}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{formTitle}</DialogTitle>
        <DialogContent>
          <div
            style={{
              minWidth: 500,
              minHeight: 30,
              padding: 5,
              fontSize: 10,
              wordWrap: "break-word",
              overflow: "hidden",
            }}
          >
            <Grid container spacing={1} columns={24}>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.MaHangMuc}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <OutlinedInput
                  id="MaHangMuc"
                  fullWidth
                  readOnly
                  value={this.props.YeuCauCG.MaHangMuc}
                />
              </Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.TenCongTy}</FormLabel>
              </Grid>
              <Grid item xs={14}>
                <MySelectFilter
                  fullWidth
                  options={this.state.DanhMuc.DM_CongTy}
                  optionValue="MaCongTy"
                  optionLabel="TenCongTy"
                  placeholder="Select contractor"
                  value={this.state.after}
                  onChange={(item) => {
                    this.saveData(item);
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} columns={24}>
              <Grid item xs={3}></Grid>
              <Grid item xs={4}>
                <Dropzone
                  maxFiles={1}
                  minSize={1}
                  validator={(file) => {
                    return false;
                  }}
                  onDrop={(acceptedFiles) => {
                    console.log(acceptedFiles);
                    this.saveField(
                      "FileBienBan",
                      acceptedFiles ? acceptedFiles[0] : null
                    );
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <Button fullWidth variant="contained">
                          File
                        </Button>
                      </div>
                    </section>
                  )}
                </Dropzone>
              </Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.Attachs}</FormLabel>
              </Grid>

              <Grid item xs={14}>
                <OutlinedInput
                  id="txtAttachs"
                  fullWidth
                  readOnly
                  value={attachs}
                />
              </Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.ResultLog}</FormLabel>
              </Grid>
              <Grid item xs={24}>
                <OutlinedInput
                  id="logs"
                  multiline
                  minRows={3}
                  maxRows={10}
                  fullWidth
                  readOnly
                  value={logs}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} columns={24}>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.PheDuyet}</FormLabel>
              </Grid>
              <Grid item xs={1}>
                <Checkbox
                  key={Math.random()}
                  sx={{ padding: 0 }}
                  checked={this.state.after.PheDuyet}
                  onChange={(event) => {
                    if (event.target.checked) {
                      var s = { after: { ...this.state.after } }; //new value
                      s.after.PheDuyet = true;
                      s.after.SoQCF = this.props.YeuCauCG.MaHangMuc.replace(
                        "RI",
                        "QCF"
                      ).replace("ReS", "QCF");
                      this.setState(s);
                    } else {
                      var s = { after: { ...this.state.after } }; //new value
                      s.after.PheDuyet = false;
                      s.after.SoQCF = null;
                      s.after.BaseNo = null;
                      s.after.NgayPheDuyet = null;
                      s.dataChanged = true;
                      this.setState(s);
                    }
                  }}
                />
              </Grid>

              <Grid item xs={3}>
                <FormLabel>{fieldLabels.SoQCF}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <OutlinedInput
                  fullWidth
                  readOnly={!this.state.after.PheDuyet}
                  value={this.state.after.SoQCF ?? ""}
                  onChange={(e) => {
                    this.saveField("SoQCF", e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.BaseNo}</FormLabel>
              </Grid>
              <Grid item xs={3}>
                <OutlinedInput
                  fullWidth
                  readOnly={!this.state.after.PheDuyet}
                  value={this.state.after.BaseNo ?? ""}
                  onChange={(e) => {
                    this.saveField("BaseNo", e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.NgayPheDuyet}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <MyDateEditor
                  fullWidth
                  readOnly={!this.state.after.PheDuyet}
                  value={this.state.after.NgayPheDuyet}
                  onChange={(value) => {
                    this.saveField("NgayPheDuyet", value);
                  }}
                />
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              this.doSave();
            }}
            variant="contained"
          >
            {buttonLabels.btnSave}
          </Button>
          <Button
            onClick={() => {
              if (this.props.close) {
                this.props.close(false);
              }
            }}
            variant="contained"
          >
            {buttonLabels.btnClose}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
