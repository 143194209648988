import {
  Button,
  FormLabel,
  Grid,
  Link,
  Paper
} from "@mui/material";
import moment from "moment";
import React from "react";
import {
  ReactDataGrid_i18n,
  formStyles,
  handleServerError,
  loadDataError,
  showError
} from "../components/common";

import ReactDataGrid from "@inovua/reactdatagrid-enterprise";
import "@inovua/reactdatagrid-enterprise/index.css";
import { withStyles } from "@mui/styles";
import "react-toastify/dist/ReactToastify.css";
/*----------------------Cac control trong project su dung den trong form----------------*/
import ButtonTimKiem from "../components/ButtonTimKiem";
import MySelectFilter from "../components/MySelectFilter";
import SimpleDialog from "../components/SimpleDialog";
import ShipYearBudgetEditDialog from "./ShipYearBudgetEditDialog";
/*-----------------------Cac thu vien dung chung -------------------------*/
const myLib = require("../lib/MyLib");
const server = require("../lib/server");
/*-----------------------Cac dinh nghia ----------------------------------*/
const englishUI = true;
/*-----------------------Dinh nghia cac label tieng Anh, tieng Viet su dung trong form*/

const fieldLabels = englishUI
  ? {
    ShipCode: "Ship:"
  }
  : {
    ShipCode: "Tàu:"
  };

const buttonLabels = englishUI
  ? {
    btnTimKiem: "Search",
    btnThem: "Add",
    btnDelete: "Delete",
  }
  : {
    btnTimKiem: "Tìm kiếm",
    btnThem: "Thêm",
    btnDelete: "Xóa"
  };
/*-----------------------Dinh nghia cac column trong grid -----------------*/
const default_gridColumns = [
  {
    name: "Nam",
    type: "string",
    headerAlign: "center",
    defaultWidth: 150,
    header: englishUI ? "Year" : "Nam"
  },
  {
    name: "FileID",
    type: "string",
    headerAlign: "center",
    flex: 1,
    header: englishUI ? "File" : "File",
    render: (props) =>
      <Link target="_blank" href={server.getRequest("ChiPhi/GetFile?FileID=" + props.data.FileID)} >{props.data.FileName}</Link>
  },
  {
    name: "NgayCN",
    type: "date",
    headerAlign: "center",
    defaultWidth: 150,
    format: "HH:mm DD/MM/YYYY",
    header: englishUI ? "Updated time" : "Ngày CN",
    render: (props) =>
      props.data.NgayCN
        ? moment(props.data.NgayCN).format("HH:mm DD/MM/YYYY")
        : null,
  },
];
/*-----------------------Cac bien su dung trong form ----------------------*/
const defaultSortInfo = { name: "Code", dir: 0, type: "string" };
//tra ve index column tuy theo sort infor
const getSortColumnIndex = (columns, sortInfor) => {
  var find_index = 0;
  for (var i = 0; i < columns.length; i++) {
    var item = columns[i];
    if (item.name == sortInfor.name) {
      find_index = i;
      break;
    }
  }
  return find_index;
};

//ham filter su dung cho combobox

class ShipYearBudgetPage extends React.Component {
  selectionCounter = 0; //dem so lan click chon row trong grid
  lastClick = 0; //danh dau lan click truoc do' cua grid de xu ly double click
  constructor(props) {
    super(props);
    this.columns_grid = default_gridColumns;
    //load lai thong tin giao dien cua phien lam viec truoc do
    this.state = {
      dataChanged: false, //du lieu co su thay doi hay khong
      loadOnStart: true, //co load du lieu ngay khi mo form khong
      errorMessage: "", //mo ta loi neu co
      errorLog: "", //mo ta chi tiet loi
      DanhMuc: {
        DM_Ship: []
      }, //luu cac du lieu danh muc su dung trong form
      //searching
      search_loading: false,
      search_result: { data: [], count: 0 }, //ket qua tim kiem
      search_dataSource: [],
      search_error: false, //search co loi khong
      lastSortInfo: defaultSortInfo, //vi grid co trang thai sortinfo = null nen phai co cai nay
      sortInfo: defaultSortInfo, //thong tin sap xep cua grid
      //chua cac tieu chi tim kiem
      after:
        props.value === undefined //gia tri truyen vao tu form cha
          ? {
            PageIndex: 1,
            PageSize: 25,
            Ascending: true,
            SortBy: getSortColumnIndex(this.columns_grid, defaultSortInfo), //index column duoc sap xep, anh xa giua ten cot duoc chon va index cua colmn trong grid
            //danh sach cac tieu chi tim kiem
            ShipID: null
          }
          : props.value, //thong tin tieu chi truyen vao tu form cha
      hasQuyen: global.hasRoles("R17")
    };
  }
  saveData = (obj) => {
    var s = this.state;
    s = { ...s, after: { ...s.after, ...obj } };
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    this.setState(s);
  };

  //ghi nhan 1 tieu chi tim kiem
  saveField = (fieldName, value) => {
    var s = this.state;
    if (fieldName !== "PageIndex") {
      s.after.PageIndex = 1;
    } else {
      if (s.after.PageIndex === value) {
        return; //ko co su thay doi thi bo qua
      }
    }
    s.after[fieldName] = value;
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    if (
      fieldName !== "PageSize" &&
      fieldName !== "PageIndex" &&
      fieldName !== "SortBy"
    ) {
      this.setMyState(this.state);
    } else {
      //lien quan den phan trang hoac sap xep thi reload
      this.setMyState(this.state, () => {
        this.doSearch();
      });
    }
  };
  //duoc su dung neu form hien thi trong che do MDI
  setMyState = (state, callback) => {
    this.setState(state, () => {
      //su dung cho muc dich MDI form
      if (this.props.onStateChanged) {
        this.props.onStateChanged(this.state);
      }
      //reload data
      if (callback) {
        callback();
      }
    });
  };
  //su kien form load
  componentDidMount() {
    this.loadDanhMuc("DM_Ship", () => {
      this.setState({
        after: {
          ...this.state.after,
          "ShipID": this.state.DanhMuc.DM_Ship?.[0]?.ShipID,
          "ShipCode": this.state.DanhMuc.DM_Ship?.[0]?.ShipCode
        }
      }, () => {
        this.doSearch();
      })
    });
  }
  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }
  static getDerivedStateFromProps() {
    return null;
  }
  shouldComponentUpdate() {
    return true;
  }

  componentDidUpdate() { }

  //load du lieu danh muc theo danh sach table
  loadDanhMuc(danhsach, nextFunc) {
    //load danh muc
    if (danhsach) {
      server
        .post("ChiPhi/DoRequest", {
          Function: "Proc_Table_GetAll",
          ThamSo: {
            TableNames: danhsach,
          },
        })
        .then((response) => {
          this.setState(
            {
              DanhMuc: {
                ...this.state.DanhMuc,
                ...response,
              },
            },
            () => nextFunc()
          );
        })
        .catch((error) => {
          showError(error);
          nextFunc();
        });
    }
  }
  //khi thay doi sap xep cot
  onSortInfoChange = (value) => {
    const newSort = value
      ? { type: value.type, name: value.name, dir: value.dir }
      : value;
    //ghi nhan vao sort by
    this.setState(
      {
        lastSortInfo: this.state.sortInfo
          ? this.state.sortInfo
          : this.state.lastSortInfo,
        sortInfo: newSort,
      },
      () => this.doSearch()
    );
  };
  loadData = ({ skip, limit }) => {
    var serverSortInfo = this.state.sortInfo
      ? this.state.sortInfo
      : this.state.lastSortInfo;

    var loader = new Promise((resolve) => {
      server
        .post("Chiphi/DoRequest", {
          Function: "Proc_CP_NganSachTau_Get",
          ThamSo: this.state.after
        })
        .then((response) => {
          this.setState({
            search_loading: false,
            search_error: false,
            search_result: {
              data: response.CP_NganSachTau,
              count: response.CP_NganSachTau.length,
            },
            rowSelected: undefined,
            rowDoubleClicked: undefined
          });
          resolve({
            data: response.CP_NganSachTau,
            count: response.CP_NganSachTau.length,
          });
        })
        .catch((error) => {
          handleServerError(error);
        });
    });
    return loader;
  };
  //Thuc hien searchf
  doSearch = () => {
    var loader = this.loadData({
      skip: (this.state.after.PageIndex - 1) * this.state.after.PageSize,
      limit: this.state.after.PageSize,
      sortInfo: this.state.sortInfo,
    });
    this.setState({
      search_loading: true,
      search_dataSource: loader, //chi load sau khi co danh muc
      selectdReecord: {},
    });
  };

  //call khi user thay doi trang
  render() {
    //lay mot so thuoc tinh truyen vao tu form cha
    const { parentHeight } = this.props;
    return (
      <div>
        <Paper variant="outlined">
          <Grid container spacing={2} columns={24}>
            <Grid item xs={1}>
              <FormLabel>{fieldLabels.ShipCode}</FormLabel>
            </Grid>
            <Grid item xs={5}>
              <MySelectFilter
                fullWidth
                autoFocus={false}
                options={this.state.DanhMuc.DM_Ship}
                optionLabel="ShipCode"
                optionValue="ShipID"
                value={this.state.after}
                onChange={(item) => {
                  this.saveData(item);
                }} />
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={2}>
              <ButtonTimKiem
                variant="contained"
                fullWidth
                title={buttonLabels.btnTimKiem}
                disabled={this.state.search_loading}
                onClick={() => {
                  this.doSearch();
                }}
              ></ButtonTimKiem>
            </Grid>
          </Grid>
        </Paper>
        <div style={{ height: 2 }}></div>
        <Grid container spacing={1} columns={24}>
          <Grid item xs={24}>
            <ReactDataGrid
              style={{
                height: parentHeight - 28 - 35,
                fontSize: 12,
              }}
              showZebraRows={true}
              i18n={englishUI ? undefined : ReactDataGrid_i18n}
              columns={this.columns_grid}
              pagination={true}
              multiSelect={false}
              showHoverRows={false}
              dataSource={this.state.search_dataSource}
              idProperty="Nam"
              headerHeight={30}

              minRowHeight={30}
              allowUnsort={false}
              defaultSortInfo={defaultSortInfo}
              sortInfo={this.state.sortInfo}
              onSortInfoChange={this.onSortInfoChange}
              onLimitChange={(v) => this.saveField("PageSize", v)}
              emptyText={loadDataError(
                this.state.search_error,
                this.state.search_result.data.length === 0,
                this.state.errorMessage,
                this.state.errorLog
              )}
              skipLoadOnMount={true}
              enableSelection={true}
              onSelectionChange={({ data }) => {
                var now = new Date().getTime();
                var diff = now - this.lastClick;
                if (diff > 300) {
                  this.setState({ rowSelected: data });
                } else {
                  //double click
                  this.setState({
                    rowSelected: data,
                    rowDoubleClicked: data,
                  });
                }
                this.lastClick = now;
              }}
            ></ReactDataGrid>
          </Grid>
          {this.state.hasQuyen && <Grid item xs={24}>
            <Grid container spacing={2} columns={24}>
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={() => {
                    var data = {
                      edittingRecord: undefined,
                      open_edit: true
                    };
                    this.setMyState(data);
                  }}
                >
                  {buttonLabels.btnThem}
                </Button>
              </Grid>
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  fullWidth
                  disabled={!this.state.rowSelected}
                  onClick={() => {
                    this.setState({ showConfirmXoa: true });
                  }}
                >
                  {buttonLabels.btnDelete}
                </Button>
              </Grid>
            </Grid>
          </Grid>}
        </Grid>
        {this.state.open_edit ? (
          <ShipYearBudgetEditDialog
            open={true}
            ShipID={this.state.after.ShipID}
            close={(saved) => {
              this.setState({ open_edit: false }, () => {
                if (saved) {
                  this.doSearch();
                }
              });
            }}
          />
        ) : null}
        {this.state.showConfirmXoa ? (
          <SimpleDialog
            confirm
            message={
              "Bạn muốn xóa: " + this.state.rowSelected.FileName + " ?"
            }
            open={true}
            OKRunning={this.state.saving}
            close={(ok) => {
              console.log(this.state.rowSelected);
              if (!this.state.saving) {
                if (ok) {
                  //thuc hien xoa
                  this.setState({ saving: true });
                  server
                    .post("ChiPhi/DoRequest", {
                      Function: "Proc_CP_NganSachTau_Delete",
                      ThamSo: {
                        Nam: this.state.rowSelected.Nam,
                        ShipID: this.state.rowSelected.ShipID
                      },
                    })
                    .then(() => {
                      this.setState({ showConfirmXoa: false, saving: false });
                      //reload
                      this.doSearch();
                    })
                    .catch((error) => {
                      this.setState({ saving: false, showConfirmXoa: false });
                      handleServerError(error);
                    });
                } else {
                  this.setState({ showConfirmXoa: false });
                }
              }
            }}
          />
        ) : null}
      </div>
    );
  }
}
export default withStyles(formStyles, { withTheme: true })(ShipYearBudgetPage);
