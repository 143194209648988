import { Download } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  FormLabel,
  Grid,
  Link,
  Paper,
  TextField,
} from "@mui/material";
import moment from "moment";
import React from "react";
import {
  ReactDataGrid_i18n,
  formStyles,
  handleServerError,
  loadDataError,
  showError,
} from "../components/common";

import ReactDataGrid from "@inovua/reactdatagrid-enterprise";
import "@inovua/reactdatagrid-enterprise/index.css";
import { withStyles } from "@mui/styles";
import "react-toastify/dist/ReactToastify.css";
/*----------------------Cac control trong project su dung den trong form----------------*/
import Dropzone from "react-dropzone";
import { toast } from "react-toastify";
import uuid from "react-uuid";
import ButtonTimKiem from "../components/ButtonTimKiem";
import MyDateEditor from "../components/MyDateEditor";
import MySelectFilter from "../components/MySelectFilter";
import SimpleDialog from "../components/SimpleDialog";
import PriceQuoteEditDialog from "./PriceQuoteEditDialog";
import PriceQuoteUploadDialog from "./PriceQuoteUploadDialog";
import QuoteConfirmUploadDialog from "./QuoteConfirmUploadDialog";
import QuoteEvaluateDialog from "./QuoteEvaluateDialog";
/*-----------------------Cac thu vien dung chung -------------------------*/
const myLib = require("../lib/MyLib");
const server = require("../lib/server");
/*-----------------------Cac dinh nghia ----------------------------------*/
const englishUI = true;
/*-----------------------Dinh nghia cac label tieng Anh, tieng Viet su dung trong form*/

const fieldLabels = englishUI
  ? {
      MaHangMuc: "Repair Items (RI):",
      NgayLap1: "From:",
      NgayLap2: "To:",
      IsDocking: "Docking:",
      TenCongTy: "Contractor:",
    }
  : {
      MaHangMuc: "Hạng mục sửa chữa:",
      NgayLap1: "Từ:",
      NgayLap2: "Đến",
      IsDocking: "Docking:",
      TenCongTy: "Nhà thầu:",
    };

const buttonLabels = englishUI
  ? {
      btnTimKiem: "Search",
      btnThem: "Add",
      btnEdit: "Edit",
      btnDelete: "Delete",
      btnDeleteFile: "Delete file",
      btnQuote: "Quote",
      btnQuoteCompare: "QC Free form",
      btnQuoteConfirm: "QCF File",
      btnCompare: "Quote Compare",
      btnQCF: "Quote Evaluate",
    }
  : {
      btnTimKiem: "Tìm kiếm",
      btnThem: "Thêm",
      btnEdit: "Sửa",
      btnDelete: "Xóa",
      btnDeleteFile: "Xóa file",
      btnQuote: "Chào giá",
      btnQuoteCompare: "So sánh chào giá",
      btnQuoteConfirm: "Biên bản chào giá",
      btnCompare: "So sánh dữ liệu chào giá",
      btnQCF: "Chấm điểm",
    };
/*-----------------------Dinh nghia cac column trong grid -----------------*/
const default_gridColumns = [
  {
    name: "MaHangMuc",
    type: "string",
    defaultWidth: 150,
    headerAlign: "center",
    header: englishUI ? "RI" : "Số HMSC",
  },
  {
    name: "SoYCCG",
    type: "string",
    defaultWidth: 130,
    headerAlign: "center",
    header: englishUI ? "RFQ No" : "Số YCCG",
  },
  {
    name: "Date",
    type: "string",
    defaultWidth: 100,
    headerAlign: "center",
    header: englishUI ? "Date" : "Ngày YCCG",
  },
  {
    name: "TenCongTy",
    type: "string",
    defaultWidth: 250,
    headerAlign: "center",
    header: englishUI ? "Contractor" : "Công ty",
    sortable: false,
    render: ({ data }) => data.TenCongTy,
  },
  {
    name: "FileCGID",
    type: "string",
    defaultWidth: 70,
    headerAlign: "center",
    header: englishUI ? "Quote" : "File chào giá",
    sortable: false,
    textAlign: "center",
    render: ({ data }) =>
      data.FileCGID ? (
        <Link
          href={
            server.getServer() + "api/ChiPhi/GetFile?fileID=" + data.FileCGID
          }
          target="_blank"
        >
          <Download />
        </Link>
      ) : (
        ""
      ),
  },
  {
    name: "FileSoSanh",
    type: "string",
    defaultWidth: 200,
    headerAlign: "center",
    header: englishUI ? "Quote compare" : "File so sánh",
    sortable: false,
    textAlign: "center",
    render: ({ data }) =>
      data.FileSoSanh ? (
        <Link
          href={
            server.getServer() + "api/ChiPhi/GetFile?fileID=" + data.FileSoSanh
          }
          target="_blank"
        >
          <Download />
        </Link>
      ) : (
        ""
      ),
  },
  {
    name: "FileBienBan",
    type: "string",
    defaultWidth: 200,
    headerAlign: "center",
    header: englishUI ? "Quote confirm" : "File biên bản",
    sortable: false,
    textAlign: "center",
    render: ({ data }) =>
      data.FileBienBan ? (
        <Link
          href={
            server.getServer() + "api/ChiPhi/GetFile?fileID=" + data.FileBienBan
          }
          target="_blank"
        >
          <Download />
        </Link>
      ) : (
        ""
      ),
  },
  {
    name: "NguoiDeNghi",
    type: "string",
    defaultWidth: 120,
    headerAlign: "center",
    header: englishUI ? "Created by" : "Người đề nghị",
    sortable: false,
  },
  {
    name: "TruongPhong",
    type: "string",
    defaultWidth: 100,
    headerAlign: "center",
    header: englishUI ? "Approved by" : "Người duyệt",
    sortable: false,
  },
  {
    name: "NgayCN",
    type: "date",
    headerAlign: "center",
    defaultWidth: 120,
    format: "HH:mm DD/MM/YYYY",
    header: englishUI ? "Updated time" : "Ngày CN",
    sortable: false,
    render: (props) =>
      props.data.NgayCN
        ? moment(props.data.NgayCN).format("HH:mm DD/MM/YYYY")
        : null,
  },
  {
    name: "ID",
    type: "number",
    defaultWidth: 90,
    headerAlign: "center",
    header: "ID",
    sortable: false,
  },
];
/*-----------------------Cac bien su dung trong form ----------------------*/
const defaultSortInfo = { name: "MaHangMuc", dir: 0, type: "string" };
//tra ve index column tuy theo sort infor
const getSortColumnIndex = (columns, sortInfor) => {
  var find_index = 0;
  for (var i = 0; i < columns.length; i++) {
    var item = columns[i];
    if (item.name == sortInfor.name) {
      find_index = i;
      break;
    }
  }
  return find_index;
};

//ham filter su dung cho combobox

class QuoteComparePage extends React.Component {
  selectionCounter = 0; //dem so lan click chon row trong grid
  lastClick = 0; //danh dau lan click truoc do' cua grid de xu ly double click
  constructor(props) {
    super(props);
    this.columns_grid = default_gridColumns;
    //load lai thong tin giao dien cua phien lam viec truoc do
    this.state = {
      dataChanged: false, //du lieu co su thay doi hay khong
      loadOnStart: true, //co load du lieu ngay khi mo form khong
      errorMessage: "", //mo ta loi neu co
      errorLog: "", //mo ta chi tiet loi
      DanhMuc: {
        DM_Complete: [
          { value: 0, label: "No" },
          { value: 1, label: "Yes" },
        ],
        DM_Docking: [
          { value: 0, label: "No" },
          { value: 1, label: "Yes" },
        ],
      }, //luu cac du lieu danh muc su dung trong form
      //searching
      search_loading: false,
      search_result: { data: [], count: 0 }, //ket qua tim kiem
      search_dataSource: [],
      search_error: false, //search co loi khong
      lastSortInfo: defaultSortInfo, //vi grid co trang thai sortinfo = null nen phai co cai nay
      sortInfo: defaultSortInfo, //thong tin sap xep cua grid
      //chua cac tieu chi tim kiem
      after:
        props.value === undefined //gia tri truyen vao tu form cha
          ? {
              PageIndex: 1,
              PageSize: 25,
              Ascending: true,
              SortBy: getSortColumnIndex(this.columns_grid, defaultSortInfo), //index column duoc sap xep, anh xa giua ten cot duoc chon va index cua colmn trong grid
              //danh sach cac tieu chi tim kiem
              MaHangMuc: null,
              NgayLap1: null,
              NgayLap2: null,
              IsDocking: null,
              TenCongTy: null,
              TenTrangThai: this.props.TenTrangThai,
            }
          : props.value, //thong tin tieu chi truyen vao tu form cha
      hasQuyen: global.hasRoles("R241"),
    };
  }
  //ghi nhan 1 tieu chi tim kiem
  saveField = (fieldName, value) => {
    var s = this.state;
    if (fieldName !== "PageIndex") {
      s.after.PageIndex = 1;
    } else {
      if (s.after.PageIndex === value) {
        return; //ko co su thay doi thi bo qua
      }
    }
    s.after[fieldName] = value;
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    if (
      fieldName !== "PageSize" &&
      fieldName !== "PageIndex" &&
      fieldName !== "SortBy"
    ) {
      this.setMyState(this.state);
    } else {
      //lien quan den phan trang hoac sap xep thi reload
      this.setMyState(this.state, () => {
        this.doSearch();
      });
    }
  };
  saveData = (obj) => {
    var s = this.state;
    s = { ...s, after: { ...s.after, ...obj } };
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    this.setState(s);
  };
  //duoc su dung neu form hien thi trong che do MDI
  setMyState = (state, callback) => {
    this.setState(state, () => {
      //su dung cho muc dich MDI form
      if (this.props.onStateChanged) {
        this.props.onStateChanged(this.state);
      }
      //reload data
      if (callback) {
        callback();
      }
    });
  };
  //su kien form load
  componentDidMount() {
    this.doSearch();
  }

  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }
  static getDerivedStateFromProps() {
    return null;
  }
  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  componentDidUpdate(prevProps) {
    //neu thong tin tau thay doi
    if (
      this.props.ShipData.ShipID !== prevProps.ShipData.ShipID ||
      this.props.TenTrangThai !== prevProps.TenTrangThai
    ) {
      //reset data
      this.state.rowSelected = undefined;
      this.setState({
        search_result: { data: [], count: 0 },
      });
      this.doSearch();
    }
  }
  //khi thay doi sap xep cot
  onSortInfoChange = (value) => {
    const newSort = value
      ? { type: value.type, name: value.name, dir: value.dir }
      : value;
    //ghi nhan vao sort by
    this.setState(
      {
        lastSortInfo: this.state.sortInfo
          ? this.state.sortInfo
          : this.state.lastSortInfo,
        sortInfo: newSort,
      },
      () => this.doSearch()
    );
  };
  loadData = ({ skip, limit }) => {
    var serverSortInfo = this.state.sortInfo
      ? this.state.sortInfo
      : this.state.lastSortInfo;

    var loader = new Promise((resolve) => {
      server
        .post("Chiphi/DoRequest", {
          Function: "Proc_CP_YeuCauCG_Search",
          ThamSo: {
            ...this.state.after,
            TenTrangThai: this.props.TenTrangThai,
            ShipID: this.props.ShipData.ShipID,
            PageIndex: skip / limit + 1,
            PageSize: limit,
            SortBy: getSortColumnIndex(this.columns_grid, serverSortInfo),
            Ascending: serverSortInfo.dir == 1,
          },
        })
        .then((response) => {
          this.setState({
            search_loading: false,
            search_error: false,
            search_result: {
              data: response.DataList,
              count: response.DataCount[0].Total,
            },
            rowSelected: undefined,
            rowDoubleClicked: undefined,
          });
          resolve({
            data: response.DataList,
            count: response.DataCount[0].Total,
          });
        })
        .catch((error) => {
          handleServerError(error);
          this.setState({
            search_loading: false,
            search_error: false,
            search_result: {
              data: [],
              count: 0,
            },
            rowSelected: undefined,
            rowDoubleClicked: undefined,
          });
          resolve({
            data: [],
            count: 0,
          });
        });
    });
    return loader;
  };
  //Thuc hien search
  doSearch = () => {
    var loader = this.loadData({
      skip: (this.state.after.PageIndex - 1) * this.state.after.PageSize,
      limit: this.state.after.PageSize,
      sortInfo: this.state.sortInfo,
    });
    this.setState({
      search_loading: true,
      search_dataSource: loader, //chi load sau khi co danh muc
      selectdReecord: {},
    });
  };

  edit() {
    server
      .post("ChiPhi/DoRequest", {
        Function: "Proc_CP_YeuCauCG_Get",
        ThamSo: {
          MaYeuCauCG: this.state.rowSelected.MaYeuCauCG,
        },
      })
      .then((response) => {
        let data = {
          edittingRecord: {
            ...response.CP_YeuCauCG[0],
            FileName: response.CP_YeuCauCG_Att?.[0]?.FileName,
          },
          open_edit: true,
        };
        this.setMyState(data);
      })
      .catch((error) => {
        showError(error);
      });
  }

  doExport(exportFormat, language) {
    server
      .post("Chiphi/DoRequest", {
        Function: "Proc_CP_YeuCauCG_Export",
        ThamSo: {
          MaYeuCauCG: this.state.rowSelected.MaYeuCauCG,
          ExportFormat: exportFormat,
          Form: language,
          Authorization: server.getToken(),
        },
      })
      .then((response) => {
        const decoded = Buffer.from(
          response.CP_YeuCauCG[0].ExcelData,
          "base64"
        );
        const blob = new Blob([decoded]);
        myLib.downloadBlob(
          blob,
          exportFormat == "Excel" ? "CP_YeuCauCG.xlsx" : "CP_YeuCauCG.docx"
        );
      })
      .catch((error) => {
        showError(error);
      });
  }

  readOneFile(fileData) {
    //tao promies de doc 1 file
    return new Promise((resolve, reject) => {
      if (fileData.File) {
        var fr = new FileReader();
        fr.onload = async (e) => {
          const text = e.target.result;
          fileData.Content = Buffer.from(text).toString("base64");
          fileData.File = undefined; //xoa file di
          resolve(fileData);
        };
        fr.onerror = reject;
        fr.readAsArrayBuffer(fileData.File);
      } else {
        resolve(fileData);
      }
    });
  }

  removeExistFile(phanLoai) {
    if (phanLoai == 1 && this.state.rowSelected.FileSoSanh) {
      return server.post("ChiPhi/DoRequest", {
        Function: "Proc_CP_HangMucSC_Att_Delete",
        ThamSo: {
          FileID: this.state.rowSelected.FileSoSanh,
        },
      });
    } else if (phanLoai == 2 && this.state.rowSelected.FileBienBan) {
      return server.post("ChiPhi/DoRequest", {
        Function: "Proc_CP_HangMucSC_Att_Delete",
        ThamSo: {
          FileID: this.state.rowSelected.FileBienBan,
        },
      });
    }

    return Promise.resolve();
  }

  doUpload = (file, phanLoai) => {
    try {
      this.setState({ saving: true, errorLog: "" });
      const fileData = {
        File: file,
        FileID: uuid(),
        FileName: file.name,
        Content: null,
      };

      this.readOneFile(fileData).then((att) => {
        this.removeExistFile(phanLoai).then((_) => {
          server
            .post("ChiPhi/DoRequest", {
              Function: "Proc_CP_HangMucSC_Att_Update",
              ThamSo: {
                HangMucID: this.state.rowSelected.HangMucID,
                LoaiFile:
                  phanLoai == 1
                    ? "So sánh chào giá"
                    : phanLoai == 2
                    ? "Biên bản chọn nhà thầu"
                    : "Khác",
                PhanLoai: phanLoai,
                FileName: att.FileName,
                Content: att.Content,
              },
            })
            .then(() => {
              this.doSearch();
              toast.success("Saved data successfully.");
              this.setState({ saving: false });
            })
            .catch((error) => {
              handleServerError(error);
              this.setState({ saving: false });
            });
        });
      });
    } catch (error) {
      this.setState({ saving: false });
      handleServerError(error);
    }
  };
  doCompare_Test = (file, phanLoai) => {
    try {
      this.setState({ saving: true, errorLog: "" });
      const fileData = {
        File: file,
        FileID: uuid(),
        FileName: file.name,
        Content: null,
      };

      this.readOneFile(fileData).then((att) => {
        var data = new FormData();
        data.append("file", file);
        server
          .upload("ChiPhi/ReadExcel", data)
          .then((response) => {
            server
              .post("ChiPhi/DoRequest", {
                Function: "Proc_CP_HangMucSC_SoSanhChaoGia",
                ThamSo: response[0],
              })
              .then(() => {
                this.setState({ saving: false });
              })
              .catch((error) => {
                handleServerError(error);
                this.setState({ saving: false });
              });
          })
          .catch((error) => {
            handleServerError(error);
            this.setState({ saving: false });
          });
      });
    } catch (error) {
      this.setState({ saving: false });
      handleServerError(error);
    }
  };
  doCompare = (file, phanLoai) => {
    try {
      this.setState({ saving: true, errorLog: "" });
      const fileData = {
        File: file,
        FileID: uuid(),
        FileName: file.name,
        Content: null,
      };

      this.readOneFile(fileData).then((att) => {
        var data = new FormData();
        data.append("file", file);
        server
          .upload("ChiPhi/SoSanhChaoGia", data)
          .then((response) => {
            const decoded = Buffer.from(response.FileContent, "base64");
            const blob = new Blob([decoded]);
            //myLib.downloadBlob(blob, response.FileName);

            server
              .post("ChiPhi/DoRequest", {
                Function: "Proc_CP_HangMucSC_Att_Update",
                ThamSo: {
                  HangMucID: this.state.rowSelected.HangMucID,
                  LoaiFile: "So sánh chào giá",

                  PhanLoai: 1,
                  FileName: file.name,
                  Content: response.FileContent,
                },
              })
              .then(() => {
                this.doSearch();
                toast.success("Saved data successfully.");
                this.setState({ saving: false });
              })
              .catch((error) => {
                handleServerError(error);
                this.setState({ saving: false });
              });
          })
          .catch((error) => {
            handleServerError(error);
            this.setState({ saving: false });
          });
      });
    } catch (error) {
      this.setState({ saving: false });
      handleServerError(error);
    }
  };
  doEvaluate = () => {
    try {
      this.setState({ saving: true });
      server
        .post("ChiPhi/DoRequest", {
          Function: "Proc_CP_HangMucSC_Att_Get",
          ThamSo: {
            HangMucID: this.state.rowSelected.HangMucID,
            PhanLoai: 1, //file so sanh chao gia
          },
        })
        .then((response) => {
          //hien thi ket qua
          if (response.CP_HangMucSC_Att.length > 0) {
            let file = response.CP_HangMucSC_Att[0];

            //////////
            server
              .post("ChiPhi/DoRequest", {
                Function: "Proc_CP_HangMucSC_SoSanhChaoGia",
                ThamSo: {
                  HangMucID: this.state.rowSelected.HangMucID,
                  FileName: file.FileName,
                  NoiDungFile: file.Content,
                },
              })
              .then((response) => {
                //hien thi ket qua
                this.setState({
                  saving: false,
                });
                var thamso = {
                  HangMucID: this.state.rowSelected.HangMucID,
                  DanhSachNhaThau: response.ChamDiem,
                };
                window.open(
                  server.getRequest(
                    "/ChiPhi/ExportQCF?thamso=" +
                      encodeURIComponent(JSON.stringify(thamso))
                  ),
                  "_blank"
                );
              })
              .catch((error) => {
                this.setState({ saving: false });
                myLib.handleServerError(error);
              });
          } else throw "Chưa có file so sánh chào giá (QC)";
        })
        .catch((error) => {
          this.setState({ saving: false });
          myLib.handleServerError(error);
        });
    } catch (ex) {
      showError(ex);
      this.setState({ saving: false });
    }
  };
  //call khi user thay doi trang
  render() {
    //lay mot so thuoc tinh truyen vao tu form cha
    const { parentHeight } = this.props;
    return (
      <div>
        <Paper variant="outlined">
          <Grid container spacing={2} columns={24}>
            <Grid item xs={3}>
              <FormLabel>{fieldLabels.MaHangMuc}</FormLabel>
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                value={this.state.after.MaHangMuc}
                onChange={(e) => {
                  this.saveField("MaHangMuc", e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <FormLabel>{fieldLabels.NgayLap1}</FormLabel>
            </Grid>
            <Grid item xs={3}>
              <MyDateEditor
                id="NgayLap1"
                key="NgayLap1"
                fullWidth
                value={this.state.after.NgayLap1}
                onChange={(value) => {
                  this.saveField("NgayLap1", value);
                }}
              />
            </Grid>
            <Grid item xs={1}>
              <FormLabel>{fieldLabels.NgayLap2}</FormLabel>
            </Grid>
            <Grid item xs={3}>
              <MyDateEditor
                id="NgayLap2"
                key="NgayLap2"
                fullWidth
                value={this.state.after.NgayLap2}
                onChange={(value) => {
                  this.saveField("NgayLap2", value);
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} columns={24}>
            <Grid item xs={3}>
              <FormLabel>{fieldLabels.IsDocking}</FormLabel>
            </Grid>
            <Grid item xs={3}>
              <MySelectFilter
                id={"IsDocking"}
                key={"IsDocking"}
                fullWidth
                autoFocus={false}
                options={this.state.DanhMuc.DM_Docking}
                optionLabel="label"
                optionValue="value"
                value={this.state.DanhMuc.DM_Docking.find(
                  (c) => c.value == this.state.after.IsDocking
                )}
                onChange={(item) => {
                  this.saveField("IsDocking", item.value);
                }}
              ></MySelectFilter>
            </Grid>
            <Grid item xs={2}>
              <FormLabel>{fieldLabels.TenCongTy}</FormLabel>
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                value={this.state.after.TenCongTy}
                onChange={(e) => {
                  this.saveField("TenCongTy", e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={3}>
              <ButtonTimKiem
                variant="contained"
                fullWidth
                title={buttonLabels.btnTimKiem}
                disabled={this.state.search_loading}
                onClick={this.doSearch}
              ></ButtonTimKiem>
            </Grid>
          </Grid>
        </Paper>
        <div style={{ height: 2 }}></div>
        <Grid container spacing={1} columns={24}>
          <Grid item xs={24}>
            <ReactDataGrid
              style={{
                height: parentHeight - 50 - 35,
                fontSize: 12,
              }}
              showZebraRows={true}
              i18n={englishUI ? undefined : ReactDataGrid_i18n}
              columns={this.columns_grid
                .filter(
                  (column) =>
                    (["RFQ", "QRC"].includes(this.props.TenTrangThai)
                      ? true
                      : column.name != "SoYCCG") &&
                    ("RFQ" == this.props.TenTrangThai
                      ? !["FileCGID", "FileSoSanh", "FileBienBan"].includes(
                          column.name
                        )
                      : true) &&
                    ("QRC" == this.props.TenTrangThai
                      ? !["FileSoSanh", "FileBienBan"].includes(column.name)
                      : true) &&
                    ("QC" == this.props.TenTrangThai
                      ? !["FileCGID", "FileBienBan", "TenCongTy"].includes(
                          column.name
                        )
                      : true) &&
                    ("QCF" == this.props.TenTrangThai
                      ? !["FileCGID", "TenCongTy"].includes(column.name)
                      : true)
                )
                .map((column) => {
                  if (column.name == "Date") {
                    return {
                      ...column,
                      render: ({ data }) => {
                        switch (this.props.TenTrangThai) {
                          case "RFQ":
                            return data.NgayYCCG
                              ? moment(data.NgayYCCG).format("DD/MM/YYYY")
                              : null;
                          case "QRC":
                            return data.NgayChaoGia
                              ? moment(data.NgayChaoGia).format("DD/MM/YYYY")
                              : null;
                          case "QC":
                            return data.NgaySoSanh
                              ? moment(data.NgaySoSanh).format("DD/MM/YYYY")
                              : null;
                          case "QCF":
                            return data.NgayQCF
                              ? moment(data.NgayQCF).format("DD/MM/YYYY")
                              : null;
                          default:
                            return data.NgayLap
                              ? moment(data.NgayLap).format("DD/MM/YYYY")
                              : null;
                        }
                      },
                    };
                  } else {
                    return column;
                  }
                })}
              pagination={true}
              multiSelect={false}
              showHoverRows={false}
              checkboxColumn={{
                render: (props) => (
                  <input
                    type="checkbox"
                    readOnly
                    checked={props.rowSelected}
                  ></input>
                ),
              }}
              defaultLimit={25}
              limit={this.state.after.PageSize}
              skip={
                (this.state.after.PageIndex - 1) * this.state.after.PageSize
              }
              onSkipChange={(skip) => {
                this.saveField(
                  "PageIndex",
                  skip / this.state.after.PageSize + 1
                );
              }}
              dataSource={this.state.search_dataSource}
              idProperty="ID"
              rowHeight={30}
              allowUnsort={false}
              defaultSortInfo={defaultSortInfo}
              sortInfo={this.state.sortInfo}
              onSortInfoChange={this.onSortInfoChange}
              onLimitChange={(v) => this.saveField("PageSize", v)}
              emptyText={loadDataError(
                this.state.search_error,
                this.state.search_result.data.length === 0,
                this.state.errorMessage,
                this.state.errorLog
              )}
              skipLoadOnMount={true}
              enableSelection={true}
              onSelectionChange={({ data }) => {
                var now = new Date().getTime();
                var diff = now - this.lastClick;
                if (diff > 300) {
                  this.setState({ rowSelected: data });
                } else {
                  //double click
                  this.edit();
                }
                this.lastClick = now;
              }}
            ></ReactDataGrid>
          </Grid>
          {this.state.hasQuyen && (
            <Grid item xs={24}>
              <Grid container spacing={2} columns={24}>
                {this.props.TenTrangThai === "RFQ" && (
                  <>
                    <Grid item xs={2}>
                      <Button
                        variant="contained"
                        fullWidth
                        disabled={!this.state.rowSelected}
                        onClick={() => {
                          this.setState({
                            edittingRecord: undefined,
                            open_edit: true,
                          });
                        }}
                      >
                        {buttonLabels.btnThem}
                      </Button>
                    </Grid>
                    <Grid item xs={3}>
                      <Button
                        variant="contained"
                        fullWidth
                        disabled={!this.state.rowSelected?.MaYeuCauCG}
                        onClick={() => {
                          this.edit();
                        }}
                      >
                        {buttonLabels.btnEdit}
                      </Button>
                    </Grid>
                    <Grid item xs={5}>
                      <Button
                        variant="contained"
                        fullWidth
                        disabled={!this.state.rowSelected?.MaYeuCauCG}
                        onClick={() => {
                          this.setState({ showConfirmXoa: true });
                        }}
                      >
                        {buttonLabels.btnDelete}
                      </Button>
                    </Grid>
                  </>
                )}
                {this.props.TenTrangThai === "QRC" && (
                  <>
                    <Grid item xs={3}>
                      <Button
                        variant="contained"
                        fullWidth
                        disabled={!this.state.rowSelected?.MaYeuCauCG}
                        onClick={() => {
                          this.setState({
                            showPriceQuoteUploadForm: true,
                          });
                        }}
                      >
                        {buttonLabels.btnQuote}
                      </Button>
                    </Grid>
                    <Grid item xs={5}>
                      <Button
                        variant="contained"
                        fullWidth
                        disabled={!this.state.rowSelected?.FileCGID}
                        onClick={() => {
                          this.setState({ showConfirmXoaFileChaoGia: true });
                        }}
                      >
                        {buttonLabels.btnDeleteFile}
                      </Button>
                    </Grid>
                  </>
                )}
                {this.props.TenTrangThai === "QC" && (
                  <>
                    <Grid item xs={7}>
                      <Dropzone
                        maxSize={2 * 1024 * 1024}
                        multiple={false}
                        accept={{
                          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                            [],
                        }}
                        validator={(file) => {
                          if (file.size > 0 && file.size < 2 * 1024 * 1024) {
                            return null;
                          }
                          alert("File too large");
                          return true;
                        }}
                        onDrop={(acceptedFiles) => {
                          if (acceptedFiles.length > 0) {
                            this.doCompare(acceptedFiles[0], 1);
                          }
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <section>
                            <div {...getRootProps()}>
                              <input {...getInputProps()} />
                              <Button
                                fullWidth
                                variant="contained"
                                disabled={!this.state.rowSelected}
                              >
                                {buttonLabels.btnCompare}
                              </Button>
                            </div>
                          </section>
                        )}
                      </Dropzone>
                    </Grid>
                    <Grid item xs={1}>
                      {this.state.saving ? (
                        <CircularProgress size={24} />
                      ) : null}
                    </Grid>
                    <Grid item xs={5}>
                      <Dropzone
                        maxSize={2 * 1024 * 1024}
                        multiple={false}
                        accept={{
                          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                            [],
                        }}
                        validator={(file) => {
                          if (file.size > 0 && file.size < 2 * 1024 * 1024) {
                            return null;
                          }
                          alert("File too large");
                          return true;
                        }}
                        onDrop={(acceptedFiles) => {
                          if (acceptedFiles.length > 0) {
                            this.doUpload(acceptedFiles[0], 1);
                          }
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <section>
                            <div {...getRootProps()}>
                              <input {...getInputProps()} />
                              <Button
                                fullWidth
                                variant="contained"
                                disabled={!this.state.rowSelected}
                              >
                                {buttonLabels.btnQuoteCompare}
                              </Button>
                            </div>
                          </section>
                        )}
                      </Dropzone>
                    </Grid>
                    <Grid item xs={5}>
                      <Button
                        variant="contained"
                        fullWidth
                        disabled={!this.state.rowSelected?.FileSoSanh}
                        onClick={() => {
                          this.setState({
                            showConfirmXoaFile: true,
                            FileToDetele: this.state.rowSelected?.FileSoSanh,
                          });
                        }}
                      >
                        {buttonLabels.btnDeleteFile}
                      </Button>
                    </Grid>
                  </>
                )}
                {this.props.TenTrangThai === "QCF" && (
                  <>
                    <Grid item xs={4}>
                      <Button
                        variant="contained"
                        fullWidth
                        disabled={!this.state.rowSelected}
                        onClick={() => {
                          this.doEvaluate();
                        }}
                      >
                        {buttonLabels.btnQCF}
                      </Button>
                    </Grid>
                    {this.state.saving ? (
                      <Grid item xs={1}>
                        <CircularProgress size={24} />
                      </Grid>
                    ) : null}
                    <Grid item xs={4}>
                      <Button
                        variant="contained"
                        fullWidth
                        disabled={!this.state.rowSelected}
                        onClick={() => {
                          this.setState({
                            showQuoteConfirmUploadForm: true,
                          });
                        }}
                      >
                        {buttonLabels.btnQuoteConfirm}
                      </Button>
                    </Grid>

                    <Grid item xs={5}>
                      <Button
                        variant="contained"
                        fullWidth
                        disabled={!this.state.rowSelected?.FileBienBan}
                        onClick={() => {
                          this.setState({
                            showConfirmXoaFile: true,
                            FileToDetele: this.state.rowSelected?.FileBienBan,
                          });
                        }}
                      >
                        {buttonLabels.btnDeleteFile}
                      </Button>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          )}
        </Grid>

        {this.state.open_edit ? (
          <PriceQuoteEditDialog
            open={true}
            HangMucID={this.state.rowSelected?.HangMucID}
            MaHangMuc={this.state.rowSelected.MaHangMuc}
            edittingRecord={this.state.edittingRecord}
            TenTrangThai={this.props.TenTrangThai}
            ShipData={this.props.ShipData}
            close={(saved) => {
              this.setState({ open_edit: false }, () => {
                if (saved) {
                  this.doSearch();
                }
              });
            }}
          />
        ) : null}
        {this.state.showConfirmXoa ? (
          <SimpleDialog
            confirm
            message={"Bạn muốn xóa: " + this.state.rowSelected.ID + " ?"}
            open={true}
            OKRunning={this.state.saving}
            close={(ok) => {
              console.log(this.state.rowSelected);
              if (!this.state.saving) {
                if (ok) {
                  //thuc hien xoa
                  this.setState({ saving: true });
                  server
                    .post("ChiPhi/DoRequest", {
                      Function: "Proc_CP_YeuCauCG_Delete",
                      ThamSo: {
                        MaYeuCauCG: this.state.rowSelected.MaYeuCauCG,
                      },
                    })
                    .then(() => {
                      this.setState({ showConfirmXoa: false, saving: false });
                      //reload
                      this.doSearch();
                    })
                    .catch((error) => {
                      this.setState({ saving: false, showConfirmXoa: false });
                      handleServerError(error);
                    });
                } else {
                  this.setState({ showConfirmXoa: false });
                }
              }
            }}
          />
        ) : null}
        {this.state.showConfirmXoaFileChaoGia ? (
          <SimpleDialog
            confirm
            message={"Bạn muốn xóa file chào giá?"}
            open={true}
            OKRunning={this.state.saving}
            close={(ok) => {
              if (!this.state.saving) {
                if (ok) {
                  //thuc hien xoa
                  this.setState({ saving: true });
                  return server
                    .post("ChiPhi/DoRequest", {
                      Function: "Proc_CP_YeuCauCG_DeleteFileChaoGia",
                      ThamSo: {
                        MaYeuCauCG: this.state.rowSelected.MaYeuCauCG,
                        FileID: this.state.rowSelected.FileCGIDs,
                      },
                    })
                    .then(() => {
                      this.setState({
                        showConfirmXoaFileChaoGia: false,
                        saving: false,
                      });
                      //reload
                      this.doSearch();
                    })
                    .catch((error) => {
                      this.setState({
                        saving: false,
                        showConfirmXoaFileChaoGia: false,
                      });
                      handleServerError(error);
                    });
                } else {
                  this.setState({ showConfirmXoaFileChaoGia: false });
                }
              }
            }}
          />
        ) : null}
        {this.state.showConfirmXoaFile ? (
          <SimpleDialog
            confirm
            message={"Bạn muốn xóa file này?"}
            open={true}
            OKRunning={this.state.saving}
            close={(ok) => {
              if (!this.state.saving) {
                if (ok) {
                  //thuc hien xoa
                  this.setState({ saving: true });
                  return server
                    .post("ChiPhi/DoRequest", {
                      Function: "Proc_CP_HangMucSC_Att_Delete",
                      ThamSo: {
                        HangMucID: this.state.rowSelected.HangMucID,
                        FileID: this.state.FileToDetele,
                      },
                    })
                    .then(() => {
                      this.setState({
                        showConfirmXoaFile: false,
                        saving: false,
                      });
                      //reload
                      this.doSearch();
                    })
                    .catch((error) => {
                      this.setState({
                        saving: false,
                        showConfirmXoaFile: false,
                      });
                      handleServerError(error);
                    });
                } else {
                  this.setState({ showConfirmXoaFile: false });
                }
              }
            }}
          />
        ) : null}
        {this.state.showPriceQuoteUploadForm ? (
          <PriceQuoteUploadDialog
            open={true}
            YeuCauCG={{
              ...this.state.rowSelected,
            }}
            close={(saved) => {
              if (saved) {
                this.setState(
                  {
                    showPriceQuoteUploadForm: false,
                  },
                  () => this.doSearch()
                );
              } else {
                this.setState({
                  showPriceQuoteUploadForm: false,
                });
              }
            }}
          />
        ) : null}
        {this.state.showQuoteConfirmUploadForm ? (
          <QuoteConfirmUploadDialog
            open={true}
            disabled={!this.state.rowSelected}
            YeuCauCG={this.state.rowSelected}
            close={(saved) => {
              if (saved) {
                this.setState(
                  {
                    showQuoteConfirmUploadForm: false,
                  },
                  () => this.doSearch()
                );
              } else {
                this.setState({
                  showQuoteConfirmUploadForm: false,
                });
              }
            }}
          />
        ) : null}
        {this.state.open_qc_evaluate ? (
          <QuoteEvaluateDialog
            open={true}
            HangMucSC={this.state.rowSelected}
            close={(saved) => {
              this.setState({
                open_qc_evaluate: false,
              });
            }}
          />
        ) : null}
      </div>
    );
  }
}
export default withStyles(formStyles, { withTheme: true })(QuoteComparePage);
