/**Nhap danh muc chu tau */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormLabel,
  Grid,
  OutlinedInput
} from "@mui/material";
import React from "react";
import Dropzone from "react-dropzone";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
/*----------------------Cac control trong project su dung den trong form----------------*/
import uuid from "react-uuid";
import {
  showError
} from "../components/common";
/*-----------------------Cac thu vien dung chung -------------------------*/
const myLib = require("../lib/MyLib");
const server = require("../lib/server");
const dataStruct = require("../data/DataStruct");
/*-----------------------Cac dinh nghia ----------------------------------*/
const englishUI = true;

/*-----------------------Dinh nghia cac label tieng Anh, tieng Viet su dung trong form*/
const formTitle = englishUI ? "PRICE QUOTE UPLOAD" : "UPLOAD CHÀO GIÁ";
const fieldLabels = englishUI
  ? {
    MaHangMuc: "Repair Item:",
    TenCongTy: "Company:",
    Attachs: "File:",
    ResultLog: "File problem:",
  }
  : {
    MaHangMuc: "Report file:",
    TenCongTy: "Company:",
    Attachs: "File:",
    ResultLog: "File problem:",
  };
const buttonLabels = englishUI
  ? {
    btnSave: "Save",
    btnClose: "Close",
  }
  : {
    btnSave: "Lưu",
    btnClose: "Thoát",
  };

////////////////////////--------------/////////////////////////////////////////
export default class PriceQuoteUploadDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      after: {
        FileChaoGia: {
          name: this.props.YeuCauCG.FileChaoGia_FileName
        },

      },
      CheckFileLog: [], //ket qua kiem tra file
    };
  }

  componentDidMount() {
    this.loadDanhMuc();
  }
  loadDanhMuc() { }
  //call khi user thay doi trang
  static getDerivedStateFromProps(nextProps, prevState) {
    return { open: nextProps.open };
  }
  saveField = (fieldName, value) => {
    var old_changed = this.state.dataChanged;
    var s = { after: { ...this.state.after } }; //new value
    s.after[fieldName] = value;
    s.dataChanged = true;
    this.setState(s);
  };
  saveData = (obj) => {
    var old_changed = this.state.dataChanged;
    var s = {};
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    s.after = { ...this.state.after, ...obj };
    this.setState(s);
  };
  doSave = () => {
    try {
      if (this.state.after.FileChaoGia && this.state.after.FileChaoGia.size) {
        this.doImport(this.state.after.FileChaoGia);
      } else {
        toast.error("Chưa có file!");
      }
    } catch (ex) {
      showError(ex);
    }
  };
  //thuc hien import file
  doImport(file) {
    try {
      var fr = new FileReader();
      fr.onload = async (e) => {
        const text = e.target.result;
        const raw = Buffer.from(text).toString("base64");
        this.doReadAttachs(raw);
      };
      fr.onerror = (err) => showError(err);
      fr.readAsArrayBuffer(file);
    } catch (ex) {
      showError(ex);
    }
  }
  doReadAttachs(fileContent) {
    //thuc hien doc tiep cac file attachs neu co
    var prom = new Promise((resolve, reject) => {
      var fr = new FileReader();
      fr.onload = async (e) => {
        const text = e.target.result;
        var fileData = {};
        fileData.FileID = uuid();
        fileData.FileName = this.state.after.FileChaoGia.name;
        fileData.FileContent = fileContent;
        resolve(fileData);
      };
      fr.onerror = reject;
      fr.readAsArrayBuffer(this.state.after.FileChaoGia);
    });
    //thuc hien viec xu ly
    prom
      .then((fileData) => {
        //thuc hien upload
        this.doUpload(fileData);
      })
      .catch((e) => showError(e));
  }
  //upload du lieu report
  doUpload(attach) {
    this.setState({ saving: true });
    server
      .post("ChiPhi/DoRequest", {
        Function: "Proc_CP_YeuCauCG_UploadChaoGia",
        ThamSo: {
          MaYeuCauCG: this.props.YeuCauCG.MaYeuCauCG,
          FileName: attach.FileName,
          NoiDungFile: attach.FileContent,
        },
      })
      .then((response) => {
        if (response.ErrorList?.length >= 0) {
          //hien thi loi
          this.setState({
            saving: false,
            CheckFileLog: response.ErrorList,
          });
        } else {
          toast.success("Cập nhật thành công!");
          this.props.close(true);
        }
      })
      .catch((error) => {
        this.setState({ saving: false });
        showError(error);
      });
  }
  render() {
    var attachs = this.state.after.FileChaoGia
      ? this.state.after.FileChaoGia.name
      : "";
    var logs = "";
    this.state.CheckFileLog.map((item, index) => logs = logs + item.STT + " - Hàng " + item.HangExcel + ", cột " + item.ColExcel + ": " + item.MoTa + "\n")
    return (
      <Dialog
        open={this.props.open}
        maxWidth={"md"}
        fullWidth={true}
        onClose={() => { }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{formTitle}</DialogTitle>
        <DialogContent>
          <div
            style={{
              minWidth: 500,
              minHeight: 30,
              padding: 5,
              fontSize: 10,
              wordWrap: "break-word",
            }}
          >
            <Grid container spacing={1} columns={24}>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.MaHangMuc}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <OutlinedInput
                  id="MaHangMuc"
                  fullWidth
                  readOnly
                  value={this.props.YeuCauCG.MaHangMuc}
                />
              </Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.TenCongTy}</FormLabel>
              </Grid>
              <Grid item xs={14}>
                <OutlinedInput
                  id="TenCongTy"
                  fullWidth
                  readOnly
                  value={this.props.YeuCauCG.TenCongTy}
                />
              </Grid>
              <Grid item xs={3}></Grid>
              <Grid item xs={4}>
                <Dropzone
                  maxFiles={1}
                  minSize={1}
                  validator={(file) => {
                    return false;
                  }}
                  onDrop={(acceptedFiles) => {
                    console.log(acceptedFiles);
                    this.saveField(
                      "FileChaoGia",
                      acceptedFiles ? acceptedFiles[0] : null
                    );
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <Button fullWidth variant="contained">
                          File
                        </Button>
                      </div>
                    </section>
                  )}
                </Dropzone>
              </Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.Attachs}</FormLabel>
              </Grid>

              <Grid item xs={14}>
                <OutlinedInput
                  id="txtAttachs"
                  fullWidth
                  readOnly
                  value={attachs}
                />
              </Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.ResultLog}</FormLabel>
              </Grid>
              <Grid item xs={24}>
                <OutlinedInput
                  id="logs"
                  multiline
                  minRows={3}
                  maxRows={10}
                  fullWidth
                  readOnly
                  value={logs}
                />
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              this.doSave();
            }}
            variant="contained"
          >
            {buttonLabels.btnSave}
          </Button>
          <Button
            onClick={() => {
              if (this.props.close) {
                this.props.close(false);
              }
            }}
            variant="contained"
          >
            {buttonLabels.btnClose}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
