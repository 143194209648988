import {
  Article,
  TableView
} from '@mui/icons-material';
import {
  Button,
  FormLabel,
  Grid,
  Paper,
  TextField
} from "@mui/material";
import moment from "moment";
import React from "react";
import {
  ReactDataGrid_i18n,
  formStyles,
  handleServerError,
  loadDataError,
  showError
} from "../components/common";

import ReactDataGrid from "@inovua/reactdatagrid-enterprise";
import "@inovua/reactdatagrid-enterprise/index.css";
import { withStyles } from "@mui/styles";
import "react-toastify/dist/ReactToastify.css";
/*----------------------Cac control trong project su dung den trong form----------------*/
import ButtonTimKiem from "../components/ButtonTimKiem";
import MenuButtons from "../components/MenuButtons";
import MyDateEditor from "../components/MyDateEditor";
import MySelectFilter from "../components/MySelectFilter";
import SimpleDialog from "../components/SimpleDialog";
import RepairSpecificationDetailDialog from "./RepairSpecificationDetail/RepairSpecificationDetailDialog";
import RepairSpecificationEditDialog from "./RepairSpecificationEditDialog";
/*-----------------------Cac thu vien dung chung -------------------------*/
const myLib = require("../lib/MyLib");
const server = require("../lib/server");
/*-----------------------Cac dinh nghia ----------------------------------*/
const englishUI = true;
/*-----------------------Dinh nghia cac label tieng Anh, tieng Viet su dung trong form*/

const fieldLabels = englishUI
  ? {
    MaHangMuc: "Repair Items (RI):",
    NgayLap1: "From:",
    NgayLap2: "To:"
  }
  : {
    MaHangMuc: "Hạng mục sửa chữa:",
    NgayLap1: "Từ:",
    NgayLap2: "Đến"
  };
const buttonExports = [
  {
    ID: "Word",
    Form: "TiengAnh",
    Label: "Word - English",
    LabelEN: "Word - English",
    Icon: <Article fontSize="small" />,
  },
  {
    ID: "Word",
    Form: "TiengViet",
    Label: "Word - Tiếng Việt",
    LabelEN: "Word - Tiếng Việt",
    Icon: <Article fontSize="small" />,
  },
  {
    ID: "Excel",
    Form: "TiengAnh",
    Label: "Excel - English",
    LabelEN: "Excel - English",
    Icon: <TableView fontSize="small" />,
  },
  {
    ID: "Excel",
    Form: "TiengViet",
    Label: "Excel - Tiếng Việt",
    LabelEN: "Excel - Tiếng Việt",
    Icon: <TableView fontSize="small" />,
  }
];
const buttonLabels = englishUI
  ? {
    btnTimKiem: "Search",
    btnThem: "Add",
    btnEdit: "Edit",
    btnDelete: "Delete",
    btnDetail: "RI & Extra RI",
    btnExport: "Export"
  }
  : {
    btnTimKiem: "Tìm kiếm",
    btnThem: "Thêm",
    btnEdit: "Sửa",
    btnDelete: "Xóa",
    btnDetail: "Chi tiết",
    btnExport: "Xuất"
  };
/*-----------------------Dinh nghia cac column trong grid -----------------*/
const default_gridColumns = [
  {
    name: "MaHangMuc",
    type: "string",
    defaultWidth: 200,
    headerAlign: "center",
    header: englishUI ? "Repair Items (RI)" : "HMSC"
  },
  {
    name: "TenHangMuc",
    type: "string",
    defaultWidth: 180,
    headerAlign: "center",
    header: englishUI ? "Subject" : "Tiêu đề",
  },
  {
    name: "PO",
    type: "string",
    defaultWidth: 100,
    headerAlign: "center",
    header: englishUI ? "Type" : "Loại",
    render: ({ data }) => data.IsDocking ? "Docking" : "Normal"
  },
  {
    name: "SoPhieuGV",
    type: "string",
    defaultWidth: 150,
    headerAlign: "center",
    header: englishUI ? "PO No." : "Số PO"
  },
  {
    name: "TenTrangThai",
    type: "string",
    defaultWidth: 100,
    headerAlign: "center",
    header: englishUI ? "Status" : "Trạng thái"
  },
  {
    name: "NguoiDeNghi",
    type: "string",
    defaultWidth: 120,
    headerAlign: "center",
    header: englishUI ? "Created by" : "Người đề nghị"
  },
  {
    name: "TruongPhong",
    type: "string",
    defaultWidth: 120,
    headerAlign: "center",
    header: englishUI ? "Approved by" : "Người duyệt"
  },
  {
    name: "NgayLap",
    type: "string",
    defaultWidth: 120,
    headerAlign: "center",
    header: englishUI ? "Created date" : "Ngày lập",
    render: ({ data }) => data.NgayLap ? moment(data.NgayLap).format("DD/MM/YYYY") : null
  },
  {
    name: "HangMucID",
    type: "string",
    defaultWidth: 60,
    headerAlign: "center",
    header: "ID"
  }
];
/*-----------------------Cac bien su dung trong form ----------------------*/
const defaultSortInfo = { name: "MaHangMuc", dir: 0, type: "string" };
//tra ve index column tuy theo sort infor
const getSortColumnIndex = (columns, sortInfor) => {
  var find_index = 0;
  for (var i = 0; i < columns.length; i++) {
    var item = columns[i];
    if (item.name == sortInfor.name) {
      find_index = i;
      break;
    }
  }
  return find_index;
};

//ham filter su dung cho combobox

class RepairSpecificationPage extends React.Component {
  selectionCounter = 0; //dem so lan click chon row trong grid
  lastClick = 0; //danh dau lan click truoc do' cua grid de xu ly double click
  constructor(props) {
    super(props);
    this.columns_grid = default_gridColumns;
    //load lai thong tin giao dien cua phien lam viec truoc do
    this.state = {
      dataChanged: false, //du lieu co su thay doi hay khong
      loadOnStart: true, //co load du lieu ngay khi mo form khong
      errorMessage: "", //mo ta loi neu co
      errorLog: "", //mo ta chi tiet loi
      DanhMuc: {
        DM_Complete: [{ value: 0, label: "No" }, { value: 1, label: "Yes" }]
      }, //luu cac du lieu danh muc su dung trong form
      //searching
      search_loading: false,
      search_result: { data: [], count: 0 }, //ket qua tim kiem
      search_dataSource: [],
      search_error: false, //search co loi khong
      lastSortInfo: defaultSortInfo, //vi grid co trang thai sortinfo = null nen phai co cai nay
      sortInfo: defaultSortInfo, //thong tin sap xep cua grid
      //chua cac tieu chi tim kiem
      after:
        props.value === undefined //gia tri truyen vao tu form cha
          ? {
            PageIndex: 1,
            PageSize: 25,
            Ascending: true,
            SortBy: getSortColumnIndex(this.columns_grid, defaultSortInfo), //index column duoc sap xep, anh xa giua ten cot duoc chon va index cua colmn trong grid
            //danh sach cac tieu chi tim kiem
            MaHangMuc: null,
            NgayLap1: null,
            NgayLap2: null
          }
          : props.value, //thong tin tieu chi truyen vao tu form cha
      hasQuyen: global.hasRoles("R241")
    };
  }
  //ghi nhan 1 tieu chi tim kiem
  saveField = (fieldName, value) => {
    var s = this.state;
    if (fieldName !== "PageIndex") {
      s.after.PageIndex = 1;
    } else {
      if (s.after.PageIndex === value) {
        return; //ko co su thay doi thi bo qua
      }
    }
    s.after[fieldName] = value;
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    if (
      fieldName !== "PageSize" &&
      fieldName !== "PageIndex" &&
      fieldName !== "SortBy"
    ) {
      this.setMyState(this.state);
    } else {
      //lien quan den phan trang hoac sap xep thi reload
      this.setMyState(this.state, () => {
        this.doSearch();
      });
    }
  };
  saveData = (obj) => {
    var s = this.state;
    s = { ...s, after: { ...s.after, ...obj } };
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    this.setState(s);
  };
  //duoc su dung neu form hien thi trong che do MDI
  setMyState = (state, callback) => {
    this.setState(state, () => {
      //su dung cho muc dich MDI form
      if (this.props.onStateChanged) {
        this.props.onStateChanged(this.state);
      }
      //reload data
      if (callback) {
        callback();
      }
    });
  };
  //su kien form load
  componentDidMount() {
    this.doSearch();
  }
  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }
  static getDerivedStateFromProps() {
    return null;
  }
  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  componentDidUpdate(prevProps) {
    //neu thong tin tau thay doi
    if (this.props.ShipData.ShipID !== prevProps.ShipData.ShipID) {
      //reset data
      this.state.rowSelected = undefined;
      this.setState({
        search_result: { data: [], count: 0 },
      });
      this.doSearch();
    }
  }
  //khi thay doi sap xep cot
  onSortInfoChange = (value) => {
    const newSort = value
      ? { type: value.type, name: value.name, dir: value.dir }
      : value;
    //ghi nhan vao sort by
    this.setState(
      {
        lastSortInfo: this.state.sortInfo
          ? this.state.sortInfo
          : this.state.lastSortInfo,
        sortInfo: newSort,
      },
      () => this.doSearch()
    );
  };
  loadData = ({ skip, limit }) => {
    var serverSortInfo = this.state.sortInfo
      ? this.state.sortInfo
      : this.state.lastSortInfo;

    var loader = new Promise((resolve) => {
      server
        .post("Chiphi/DoRequest", {
          Function: "Proc_CP_HangMucSC_Search",
          ThamSo: {
            ...this.state.after,
            ShipID: this.props.ShipData.ShipID,
            PageIndex: skip / limit + 1,
            PageSize: limit,
            SortBy: getSortColumnIndex(this.columns_grid, serverSortInfo),
            Ascending: serverSortInfo.dir == 1,
          },
        })
        .then((response) => {
          this.setState({
            search_loading: false,
            search_error: false,
            search_result: {
              data: response.DataList,
              count: response.DataCount[0].Total,
            },
            rowSelected: undefined,
            rowDoubleClicked: undefined,
            ReportContent: "",
            Attachs: [],
          });
          resolve({
            data: response.DataList,
            count: response.DataCount[0].Total,
          });
        })
        .catch((error) => {
          var message = "";
          var log = "";
          try {
            var obj = JSON.parse(error);
            message = obj.message;
            log = obj.traceLog;
          } catch (e) {
            message = error + "";
          }
          this.setState({
            search_loading: false,
            search_error: true,
            errorMessage: message,
            errorLog: log,
            search_result: { data: [], count: 0 },
            rowSelected: undefined,
          });
          resolve({ data: [], count: 0 });
        });
    });
    return loader;
  };
  //Thuc hien search
  doSearch = () => {
    var loader = this.loadData({
      skip: (this.state.after.PageIndex - 1) * this.state.after.PageSize,
      limit: this.state.after.PageSize,
      sortInfo: this.state.sortInfo,
    });
    this.setState({
      search_loading: true,
      search_dataSource: loader, //chi load sau khi co danh muc
      selectdReecord: {},
    });
  };

  loadPreview() { }

  //thuc hien import file
  doImport(files) {
    if (Array.isArray(files) && files.length == 1) {
      try {
        var fr = new FileReader();
        fr.onload = async (e) => {
          const text = e.target.result;
          const json = {
            "FromShip": true,
            "FileName": files[0].name,
            "NoiDungFile": Buffer.from(text).toString("base64")
          };
          this.doUpload(json);
        };
        fr.onerror = (err) => showError(err);
        fr.readAsArrayBuffer(files[0]);
      } catch (ex) {
        showError(ex);
      }
    }
  }
  //upload du lieu report
  doUpload(json) {
    this.setState({ saving: true });
    server
      .post("ChiPhi/DoRequest", {
        Function: "Proc_CP_YeuCauSC_Import",
        ThamSo: json,
      })
      .then((response) => {
        this.setState(
          {
            saving: false,
          },
          () => this.doSearch()
        );
      })
      .catch((error) => {
        this.setState({ saving: false });
        showError(error);
      });
  }

  edit() {
    server
      .post("ChiPhi/DoRequest", {
        Function: "Proc_CP_HangMucSC_Get",
        ThamSo: {
          HangMucID: this.state.rowSelected.HangMucID,
        },
      })
      .then((response) => {
        let data = {
          edittingRecord: {
            ...response.CP_HangMucSC[0],
            NoiDung: response.CP_HangMucSC_ND.map(item => {
              return {
                ...item,
                MaYeuCau: item.MaYeuCau ?? item.MaYeuCauSC,
                MaNoiDung: item.MaNoiDung ?? item.MaNoiDungYC,
                MucLuc: item.STTND
              }
            })
          },
          open_edit: true
        };
        this.setMyState(data);
      })
      .catch((error) => {
        showError(error);
      });
  }

  doExport(exportFormat, language) {
    server
      .post("Chiphi/DoRequest", {
        Function: "Proc_CP_HangMucSC_Export",
        ThamSo: {
          HangMucID: this.state.rowSelected.HangMucID,
          ExportFormat: exportFormat,
          Form: language,
          Authorization: server.getToken()
        },
      })
      .then((response) => {
        const decoded = Buffer.from(response.CP_HangMucSC[0].ExcelData, "base64");
        const blob = new Blob([decoded]);
        myLib.downloadBlob(blob, exportFormat == "Excel" ? "CP_HangMucSC.xlsx" : "CP_HangMucSC.docx");
      })
      .catch((error) => {
        showError(error);
      });
  }

  //call khi user thay doi trang
  render() {
    //lay mot so thuoc tinh truyen vao tu form cha
    const { parentHeight } = this.props;
    return (
      <div>
        <Paper variant="outlined">
          <Grid container spacing={2} columns={24}>
            <Grid item xs={3}>
              <FormLabel>{fieldLabels.MaHangMuc}</FormLabel>
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                value={this.state.after.MaHangMuc}
                onChange={(e) => {
                  this.saveField("MaHangMuc", e.target.value);
                }} />
            </Grid>
            <Grid item xs={2}>
              <FormLabel>{fieldLabels.NgayLap1}</FormLabel>
            </Grid>
            <Grid item xs={3}>
              <MyDateEditor
                id="NgayLap1"
                key="NgayLap1"
                fullWidth
                value={this.state.after.NgayLap1}
                onChange={(value) => {
                  this.saveField("NgayLap1", value);
                }}
              />
            </Grid>
            <Grid item xs={1}>
              <FormLabel>{fieldLabels.NgayLap2}</FormLabel>
            </Grid>
            <Grid item xs={3}>
              <MyDateEditor
                id="NgayLap2"
                key="NgayLap2"
                fullWidth
                value={this.state.after.NgayLap2}
                onChange={(value) => {
                  this.saveField("NgayLap2", value);
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <ButtonTimKiem
                variant="contained"
                fullWidth
                title={buttonLabels.btnTimKiem}
                disabled={this.state.search_loading}
                onClick={this.doSearch}
              ></ButtonTimKiem>
            </Grid>
          </Grid>
        </Paper>
        <div style={{ height: 2 }}></div>
        <Grid container spacing={1} columns={24}>
          <Grid item xs={24}>
            <ReactDataGrid
              style={{
                height: parentHeight - 60,
                fontSize: 12,
              }}
              showZebraRows={true}
              i18n={englishUI ? undefined : ReactDataGrid_i18n}
              columns={this.columns_grid}
              pagination={true}
              multiSelect={false}
              showHoverRows={false}
              checkboxColumn={{
                render: (props) => (
                  <input
                    type="checkbox"
                    readOnly
                    checked={props.rowSelected}
                  ></input>
                ),
              }}
              defaultLimit={25}
              limit={this.state.after.PageSize}
              skip={
                (this.state.after.PageIndex - 1) * this.state.after.PageSize
              }
              onSkipChange={(skip) => {
                this.saveField(
                  "PageIndex",
                  skip / this.state.after.PageSize + 1
                );
              }}
              dataSource={this.state.search_dataSource}
              idProperty="MaHangMuc"
              rowHeight={30}
              allowUnsort={false}
              defaultSortInfo={defaultSortInfo}
              sortInfo={this.state.sortInfo}
              onSortInfoChange={this.onSortInfoChange}
              onLimitChange={(v) => this.saveField("PageSize", v)}
              emptyText={loadDataError(
                this.state.search_error,
                this.state.search_result.data.length === 0,
                this.state.errorMessage,
                this.state.errorLog
              )}
              skipLoadOnMount={true}
              enableSelection={true}
              onSelectionChange={({ data }) => {
                var now = new Date().getTime();
                var diff = now - this.lastClick;
                if (diff > 300) {
                  this.setState({ rowSelected: data, ReportContent: "" }, () =>
                    this.loadPreview()
                  );
                } else {
                  //double click
                  this.setState({
                    rowSelected: data,
                    rowDoubleClicked: data,
                    open_detail: true,
                  });
                }
                this.lastClick = now;
              }}
            ></ReactDataGrid>
          </Grid>
          {this.state.hasQuyen && <Grid item xs={24}>
            <Grid container spacing={2} columns={24}>
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={() => {
                    var data = {
                      edittingRecord: undefined,
                      open_edit: true
                    };
                    this.setMyState(data);
                  }}
                >
                  {buttonLabels.btnThem}
                </Button>
              </Grid>
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  fullWidth
                  disabled={!this.state.rowSelected}
                  onClick={() => {
                    this.edit();
                  }}
                >
                  {buttonLabels.btnEdit}
                </Button>
              </Grid>
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  fullWidth
                  disabled={!this.state.rowSelected}
                  onClick={() => {
                    this.setState({ showConfirmXoa: true });
                  }}
                >
                  {buttonLabels.btnDelete}
                </Button>
              </Grid>
              <Grid item xs={5}>
                <Button
                  variant="contained"
                  fullWidth
                  disabled={!this.state.rowSelected}
                  onClick={() => {
                    this.setState({ open_detail: true });
                  }}
                >
                  {buttonLabels.btnDetail}
                </Button>
              </Grid>
              <Grid item xs={2}>
                <MenuButtons
                  fullWidth
                  englishUI={englishUI}
                  title={buttonLabels.btnExport}
                  SubActions={buttonExports}
                  disabled={!this.state.rowSelected}
                  onClick={(subButton) => {
                    this.doExport(subButton.ID, subButton.Form);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>}
        </Grid>

        {this.state.open_edit ? (
          <RepairSpecificationEditDialog
            open={true}
            edittingRecord={this.state.edittingRecord}
            ShipData={this.props.ShipData}
            close={(saved) => {
              this.setState({ open_edit: false }, () => {
                if (saved) {
                  this.doSearch();
                }
              });
            }}
          />
        ) : null}
        {this.state.showConfirmXoa ? (
          <SimpleDialog
            confirm
            message={
              "Bạn muốn xóa: " + this.state.rowSelected.MaHangMuc + " ?"
            }
            open={true}
            OKRunning={this.state.saving}
            close={(ok) => {
              console.log(this.state.rowSelected);
              if (!this.state.saving) {
                if (ok) {
                  //thuc hien xoa
                  this.setState({ saving: true });
                  server
                    .post("ChiPhi/DoRequest", {
                      Function: "Proc_CP_HangMucSC_Delete",
                      ThamSo: {
                        HangMucID: this.state.rowSelected.HangMucID,
                      },
                    })
                    .then(() => {
                      this.setState({ showConfirmXoa: false, saving: false });
                      //reload
                      this.doSearch();
                    })
                    .catch((error) => {
                      this.setState({ saving: false, showConfirmXoa: false });
                      handleServerError(error);
                    });
                } else {
                  this.setState({ showConfirmXoa: false });
                }
              }
            }}
          />
        ) : null}
        {this.state.open_detail ? (
          <RepairSpecificationDetailDialog
            open={true}
            HangMucID={this.state.rowSelected.HangMucID}
            edittingRecord={this.state.rowSelected}
            ShipData={this.props.ShipData}
            close={(saved) => {
              this.setState({ open_detail: false }, () => {
                if (saved) {
                  this.doSearch();
                }
              });
            }}
          />
        ) : null}
      </div>
    );
  }
}
export default withStyles(formStyles, { withTheme: true })(RepairSpecificationPage);
