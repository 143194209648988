import ReactDataGrid from "@inovua/reactdatagrid-enterprise";
import "@inovua/reactdatagrid-enterprise/index.css";
import {
  Button,
  Grid
} from "@mui/material";
import { withStyles } from "@mui/styles";
import moment from "moment";
import React from "react";
import "react-toastify/dist/ReactToastify.css";
/*----------------------Cac control trong project su dung den trong form----------------*/
import SimpleDialog from "../../components/SimpleDialog";
import {
  ReactDataGrid_i18n,
  formStyles,
  handleServerError,
  loadDataError
} from "../../components/common";
import AttachmentDocumentEditDialog from "./AttachmentDocumentEditDialog";
/*-----------------------Cac thu vien dung chung -------------------------*/
const myLib = require("../../lib/MyLib");
const server = require("../../lib/server");
/*-----------------------Cac dinh nghia ----------------------------------*/
const englishUI = true;
/*-----------------------Dinh nghia cac label tieng Anh, tieng Viet su dung trong form*/

const buttonLabels = englishUI
  ? {
    btnThem: "Add",
    btnDownload: "Download",
    btnDelete: "Delete"
  }
  : {
    btnThem: "Thêm",
    btnDownload: "Tải về",
    btnDelete: "Xóa"
  };
/*-----------------------Dinh nghia cac column trong grid -----------------*/
const default_gridColumns = [
  {
    name: "PhanLoai",
    type: "string",
    headerAlign: "center",
    header: englishUI ? "Category" : "Phân loại",
    render: ({data}) => {
      switch (data.PhanLoai) {
        case 1:
          return "So sánh chào giá";
        case 2:
          return "Biên bản chọn nhà thầu";
        case 3:
          return englishUI ? "Commercial Doc" : "Hồ sơ Tài chính";
        case 4:
          return englishUI ? "Technical Doc" : "Tài liệu Kỹ thuật";
        case 5:
          return englishUI ? "Communication Doc" : "Thông tin qua lại";
        default:
          return englishUI ? "Other" : "Khác";
      }
    }
  },
  {
    name: "FileName",
    type: "string",
    defaultFlex: 1,
    headerAlign: "center",
    header: englishUI ? "File name" : "Tên file",
    render: ({ data }) => data.FileName
  },
  {
    name: "NgayCN",
    type: "date",
    headerAlign: "center",
    format: "HH:mm DD/MM/YYYY",
    header: englishUI ? "Updated time" : "Ngày CN",
    render: (props) =>
      props.data.NgayCN
        ? moment(props.data.NgayCN).format("HH:mm DD/MM/YYYY")
        : null,
  },
  {
    name: "FileID",
    type: "string",
    defaultWidth: 260,
    headerAlign: "center",
    header: "ID"
  }
];
export const gridColumns_groups = [
  { name: "TinhTrang", header: englishUI ? "Status" : "Tình trạng", headerAlign: "center" }
];
/*-----------------------Cac bien su dung trong form ----------------------*/
const defaultSortInfo = { name: "MaYeuCau", dir: 0, type: "string" };
//tra ve index column tuy theo sort infor
const getSortColumnIndex = (columns, sortInfor) => {
  var find_index = 0;
  for (var i = 0; i < columns.length; i++) {
    var item = columns[i];
    if (item.name == sortInfor.name) {
      find_index = i;
      break;
    }
  }
  return find_index;
};

class AttachmentDocumentsTab extends React.Component {
  lastClick = 0; //danh dau lan click truoc do' cua grid de xu ly double click
  constructor(props) {
    super(props);
    this.columns_grid = default_gridColumns;
    //load lai thong tin giao dien cua phien lam viec truoc do
    this.state = {
      dataChanged: false, //du lieu co su thay doi hay khong
      loadOnStart: true, //co load du lieu ngay khi mo form khong
      errorMessage: "", //mo ta loi neu co
      errorLog: "", //mo ta chi tiet loi
      DanhMuc: {
        DM_Complete: [{ value: 0, label: "No" }, { value: 1, label: "Yes" }]
      }, //luu cac du lieu danh muc su dung trong form
      //searching
      search_loading: false,
      search_result: { data: [], count: 0 }, //ket qua tim kiem
      search_dataSource: [],
      search_error: false, //search co loi khong
      lastSortInfo: defaultSortInfo, //vi grid co trang thai sortinfo = null nen phai co cai nay
      sortInfo: defaultSortInfo, //thong tin sap xep cua grid
      //chua cac tieu chi tim kiem
      after:
        props.value === undefined //gia tri truyen vao tu form cha
          ? {
            Ascending: true,
            SortBy: getSortColumnIndex(this.columns_grid, defaultSortInfo), //index column duoc sap xep, anh xa giua ten cot duoc chon va index cua colmn trong grid
          }
          : props.value, //thong tin tieu chi truyen vao tu form cha
    };
  }
  //ghi nhan 1 tieu chi tim kiem
  saveField = (fieldName, value) => {
    var s = this.state;
    if (fieldName !== "PageIndex") {
      s.after.PageIndex = 1;
    } else {
      if (s.after.PageIndex === value) {
        return; //ko co su thay doi thi bo qua
      }
    }
    s.after[fieldName] = value;
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    if (
      fieldName !== "PageSize" &&
      fieldName !== "PageIndex" &&
      fieldName !== "SortBy"
    ) {
      this.setMyState(this.state);
    } else {
      //lien quan den phan trang hoac sap xep thi reload
      this.setMyState(this.state, () => {
        this.doSearch();
      });
    }
  };
  saveData = (obj) => {
    var s = this.state;
    s = { ...s, after: { ...s.after, ...obj } };
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    this.setState(s);
  };
  //duoc su dung neu form hien thi trong che do MDI
  setMyState = (state, callback) => {
    this.setState(state, () => {
      //su dung cho muc dich MDI form
      if (this.props.onStateChanged) {
        this.props.onStateChanged(this.state);
      }
      //reload data
      if (callback) {
        callback();
      }
    });
  };
  //su kien form load
  componentDidMount() {
    this.doSearch();
  }
  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }
  static getDerivedStateFromProps() {
    return null;
  }
  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  componentDidUpdate(prevProps) {
    //neu thong tin tau thay doi
    if (this.props.ShipData.ShipID !== prevProps.ShipData.ShipID) {
      //reset data
      this.state.rowSelected = undefined;
      this.setState({
        search_result: { data: [], count: 0 },
      });
      this.doSearch();
    }
  }
  //khi thay doi sap xep cot
  onSortInfoChange = (value) => {
    const newSort = value
      ? { type: value.type, name: value.name, dir: value.dir }
      : value;
    //ghi nhan vao sort by
    this.setState(
      {
        lastSortInfo: this.state.sortInfo
          ? this.state.sortInfo
          : this.state.lastSortInfo,
        sortInfo: newSort,
      },
      () => this.doSearch()
    );
  };
  loadData = ({ skip, limit }) => {
    var serverSortInfo = this.state.sortInfo
      ? this.state.sortInfo
      : this.state.lastSortInfo;

    var loader = new Promise((resolve) => {
      server
        .post("Chiphi/DoRequest", {
          Function: "Proc_CP_HangMucSC_Get",
          ThamSo: {
            HangMucID: this.props.HangMucID
          },
        })
        .then((response) => {
          this.setState({
            search_loading: false,
            search_error: false,
            search_result: {
              data: response.CP_HangMucSC_Att,
              count: response.CP_HangMucSC_Att.Total,
            },
            rowSelected: undefined,
            rowDoubleClicked: undefined,
            ReportContent: "",
            Attachs: [],
          });
          resolve({
            data: response.CP_HangMucSC_Att,
            count: response.CP_HangMucSC_Att.Total,
          });
        })
        .catch((error) => {
          var message = "";
          var log = "";
          try {
            var obj = JSON.parse(error);
            message = obj.message;
            log = obj.traceLog;
          } catch (e) {
            message = error + "";
          }
          this.setState({
            search_loading: false,
            search_error: true,
            errorMessage: message,
            errorLog: log,
            search_result: { data: [], count: 0 },
            rowSelected: undefined,
          });
          resolve({ data: [], count: 0 });
        });
    });
    return loader;
  };
  //Thuc hien search
  doSearch = () => {
    var loader = this.loadData({
      skip: (this.state.after.PageIndex - 1) * this.state.after.PageSize,
      limit: this.state.after.PageSize,
      sortInfo: this.state.sortInfo,
    });
    this.setState({
      search_loading: true,
      search_dataSource: loader, //chi load sau khi co danh muc
      selectdReecord: {},
    });
  };

  render() {
    return (
      <>
        <Grid container spacing={1} columns={24} style={{
          height: "100%",
        }}>
          <Grid item xs={24} style={{
            height: "calc(100% - 35px)",
          }}>
            <ReactDataGrid
              style={{
                height: "100%",
                fontSize: 12,
              }}
              showZebraRows={true}
              i18n={englishUI ? undefined : ReactDataGrid_i18n}
              columns={this.columns_grid}
              groups={gridColumns_groups}
              pagination={false}
              multiSelect={false}
              showHoverRows={false}
              checkboxColumn={{
                render: (props) => (
                  <input
                    type="checkbox"
                    readOnly
                    checked={props.rowSelected}
                  ></input>
                ),
              }}
              dataSource={this.state.search_dataSource}
              idProperty="FileID"
              rowHeight={30}
              allowUnsort={false}
              defaultSortInfo={defaultSortInfo}
              sortInfo={this.state.sortInfo}
              onSortInfoChange={this.onSortInfoChange}
              emptyText={loadDataError(
                this.state.search_error,
                this.state.search_result.data.length === 0,
                this.state.errorMessage,
                this.state.errorLog
              )}
              skipLoadOnMount={true}
              enableSelection={true}
              onSelectionChange={({ data }) => {
                var now = new Date().getTime();
                var diff = now - this.lastClick;
                if (diff > 300) {
                  this.setState({ rowSelected: data, ReportContent: "" });
                } else {
                  //double click
                  this.setState({
                    rowSelected: data,
                    rowDoubleClicked: data,
                  });
                }
                this.lastClick = now;
              }}
            ></ReactDataGrid>
          </Grid>
          <Grid item xs={24}>
            <Grid container spacing={2} columns={24}>
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={() => {
                    var data = {
                      edittingRecord: undefined,
                      open_edit: true
                    };
                    this.setMyState(data);
                  }}
                >
                  {buttonLabels.btnThem}
                </Button>
              </Grid>
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  fullWidth
                  disabled={!this.state.rowSelected}
                  href={server.getRequest(`ChiPhi/GetFile?fileID=${this.state.rowSelected?.FileID}`)}
                  target="_blank"
                >
                  {buttonLabels.btnDownload}
                </Button>
              </Grid>
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  fullWidth
                  disabled={!this.state.rowSelected}
                  onClick={() => {
                    this.setState({ showConfirmXoa: true });
                  }}
                >
                  {buttonLabels.btnDelete}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {this.state.open_edit ? (
          <AttachmentDocumentEditDialog
            open={true}
            edittingRecord={this.state.edittingRecord}
            HangMucID={this.props.HangMucID}
            close={(saved) => {
              this.setState({ open_edit: false }, () => {
                if (saved) {
                  this.doSearch();
                }
              });
            }}
          />
        ) : null}
        {this.state.showConfirmXoa ? (
          <SimpleDialog
            confirm
            message={
              "Bạn muốn xóa: " + this.state.rowSelected.FileName + " ?"
            }
            open={true}
            OKRunning={this.state.saving}
            close={(ok) => {
              console.log(this.state.rowSelected);
              if (!this.state.saving) {
                if (ok) {
                  //thuc hien xoa
                  this.setState({ saving: true });
                  server
                    .post("ChiPhi/DoRequest", {
                      Function: "Proc_CP_HangMucSC_Att_Delete",
                      ThamSo: {
                        FileID: this.state.rowSelected.FileID,
                      }
                    })
                    .then(() => {
                      this.setState({ showConfirmXoa: false, saving: false });
                      //reload
                      this.doSearch();
                    })
                    .catch((error) => {
                      this.setState({ saving: false, showConfirmXoa: false });
                      handleServerError(error);
                    });
                } else {
                  this.setState({ showConfirmXoa: false });
                }
              }
            }}
          />
        ) : null}
      </>
    )
  }
}

export default withStyles(formStyles, { withTheme: true })(AttachmentDocumentsTab);